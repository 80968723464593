/**
 * ブラウザの言語設定で日本語が使用可能かどうか判定する。
 * @returns 日本語が使用可能であれば true
 */ 
export const canDisplayJapanese = () => {
  const language = window.navigator.languages || [
    window.navigator.language
  ]
  return language.includes("ja")
}

export const text = {
  "title": {
    "before": {
      "title": "走行前チェック",
      "title_en": "Before driving check",
    },
    "after": {
      "title": "走行後チェック",
      "title_en": "After driving check",
    },
  },
  "complete_message": {
    "sentence": "チェック結果を送信しました。",
    "sentence_en": "Completed."
  },
  "message": {
    "unexpected_error": {
      "ja": "予期せぬエラーが発生しました。システム管理者に連絡してください。",
      "en": "An unexpected error has occurred. Contact your system administrator."
    }
  }
}