
/**
 * Date を ISO 8601 拡張形式の文字列に変換する。
 * 
 * @remarks
 * 日時の扱いについて
 * 申請日時、承認日時などの日時は現地時間を、TZ offset(UTCまでのオフセット時間)とともに画面に表示する。
 * - サーバーへの送信データ
 *   以下を送信する
 *   - UTC時刻(エポック秒)(秒)
 *   - TZ offset(UTCまでのオフセット(分))
 *     ※ jsのgetTimezoneoffset()で取得した値をそのままサーバーに送信する
 *        UTCより遅れている場合はプラス、進んでいる場合はマイナスの値となる
 * ・画面表示
 *   - サーバーからはUTCエポック[秒]とオフセット[分]を受信する
 *   - UTCエポック秒からjsのDate型に変換し、本関数を利用してフォーマットする
 * 
 * ※注意 js のDate型は、UTCエポック秒を渡してもローカルタイムとしてインスタンス化するため、
 * サーバーから受信したUTCエポック秒にあらかじめ、ローカルのTZ offsetをオフセットしておき、
 * Dateのコンストラクタに渡す
 * 
 * @param date
 * @param offset UTC に対するオフセット（分）
 * @returns ISO8601拡張形式の文字列
 */
export const formatISO8601 = (date: Date, offset: number) => {

  return [
    [
      date.getFullYear(),
      ("00" + (date.getMonth() + 1)).substr(-2),
      ("00" + date.getDate()).substr(-2)
    ].join("-"),
    "T",
    [
      ("0" + date.getHours()).slice(-2),
      ("0" + date.getMinutes()).slice(-2),
    ].join(":"),
    (offset > 0 ? "-" : "+") + ("00" + Math.abs(offset / 60)).substr(-2) + ":00"
  ].join("");
}

/**
 * Date をYYYYMMDD_hhmmss形式にフォーマットする
 * @param date
 * @returns YYYYMMDD_hhmmss形式の文字列
 */
export const formatYYYYMMDD_hhmmss = (date: Date) => {

  return [
    date.getFullYear(),
    ("00" + (date.getMonth() + 1)).substr(-2),
    ("00" + date.getDate()).substr(-2),
    "_",
    ("0" + date.getHours()).slice(-2),
    ("0" + date.getMinutes()).slice(-2),
    ("0" + date.getSeconds()).slice(-2),
  ].join("");
}

/**
 * Date をYYYYMMDD形式にフォーマットする
 * @param date
 * @returns YYYYMMDD形式の文字列
 */
export const formatYYYYMMDD = (date: Date) => {
  return [
    date.getFullYear(),
    "-",
    ("00" + (date.getMonth() + 1)).substr(-2),
    "-",
    ("00" + date.getDate()).substr(-2),
  ].join("");
}
