import React from "react";
import {
  Button,
  Menu, MenuItem, MenuProps,
  withStyles
} from "@material-ui/core";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


// フィルターボタン押下時に表示するメニューをスタイルする。
const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    {...props}
  />
));

type FilterMenuProps<T extends string> = {
  name: string  // フィルターボタンに表示するフィルターの名称
  candidates: readonly T[]  // フィルターの候補
  selected: T | null  // 現在選択中のフィルター
  setSelected: (selected: T | null) => void // フィルターが選択された場合のコールバック関数
}

/**
 * フィルターボタンコンポーネント
 * @param props
 */
export const FilterMenu = <T extends string>(props: FilterMenuProps<T>) => {
  const {name, candidates, selected, setSelected} = props
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const unSelectFilter = React.useCallback(() => {
    setSelected(null)
  }, [setSelected])

  const selectFilter = (selected: T) => {
    setSelected(selected)
    setAnchorEl(null)
  }

  return (
    <React.Fragment>
      {selected
        ? <Button variant="contained" color="secondary" style={{ marginTop: 10, marginLeft: 10 }}
                  onClick={unSelectFilter}>
            <FontAwesomeIcon icon={faFilter} style={{ marginRight: 4 }} /> Clear Filter
          </Button>
        : <Button variant="contained" color="primary" style={{ marginTop: 10, marginLeft: 10 }}
                  onClick={(e) => setAnchorEl(e.currentTarget)}>
            <FontAwesomeIcon icon={faFilter} style={{ marginRight: 4 }} /> Filter by {name}
          </Button>
      }
      <StyledMenu
        anchorEl={anchorEl}
        keepMounted
        elevation={0}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}>
        {candidates.map(value => <MenuItem key={value} onClick={() => selectFilter(value)}>{value}</MenuItem>)}
      </StyledMenu>
    </React.Fragment>
  )
}
