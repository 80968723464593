import { Button, createStyles, Grid, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Theme, Typography } from "@material-ui/core"
import axios from "axios"
import ChipInput from "material-ui-chip-input"
import React, { useEffect } from "react"
import { QuestionGroupType, QuestionType } from "./checkPage"
import { v4 } from "uuid"
import { useNotificationDispatch } from "../notification"
import { canDisplayJapanese, text } from "../localize"

type QuestionGroupEdit = Omit<QuestionGroupType, 'questions'> & {
  key: string
  questions: QuestionEdit[]
}

type QuestionEdit = QuestionType & {
  key: string
}

const useStyles = makeStyles((_: Theme) =>
  createStyles({
    paper: {
      marginTop: 30,
    },
    category: {
      padding: 5,
    },
    sentence: {
      margin: "5px 0px",
    },
    tableButton: {
      margin: 2,
    },
    button: {
      margin: "10px 2px",
    },
  })
)

// 入力必須項目のバリデーション
const validateForm = (form: QuestionGroupEdit[]) => {
  return form.every(group =>
    group.category !== "" &&
    group.category_en !== "" &&
    group.questions.every(question =>
      question.sentence !== "" &&
      question.sentence_en !== ""
    )
  )
}

/**
 * チェック項目編集画面
 */
export const CheckEdit: React.FC = () => {
  const classes = useStyles()
  const [beforeDriving, setBeforeDriving] = React.useState(true)
  const [form, setForm] = React.useState<QuestionGroupEdit[]>([])
  const [validated, setValidated] = React.useState(true)
  const { success, error } = useNotificationDispatch()

  const changeForm = React.useCallback((beforeDriving: boolean) => {
    axios.get(beforeDriving ? "/applications/form/before" : "/applications/form/after")
      .then(response => {
        // 初めて項目編集する場合は空のデータを格納する。
        const groups: QuestionGroupEdit[] = response.data.groups.length > 0 ? response.data.groups : [{ category: "", category_en: "", questions: [{ sentence: "", sentence_en: "", candidates: [], unit: "" }] }]
        // group と question それぞれに ユニークな key 属性を付加する。(React がレンダリングのために必要とする。)
        groups.forEach(e => {
          e.key = v4()
          e.questions.forEach(q => {
            q.key = v4()
          })
        })
        setForm(groups)
      }).catch(err => {
        error(
          err.response?.data?.detail?.messageJa || `${text.message.unexpected_error.ja} ${err.message}`,
          err.response?.data?.detail?.messageEn || `${text.message.unexpected_error.en} ${err.message}`
        )
      })
  }, [error])

  useEffect(() => {
    changeForm(beforeDriving)
  }, [beforeDriving, changeForm])

  const addCategory = (index: number) => {
    const newForm: typeof form = JSON.parse(JSON.stringify(form)) // Json object を deep copy する
    // ひとつ下に空欄を追加
    newForm.splice(index + 1, 0, { key: v4(), category: "", category_en: "", questions: [{ key: v4(), sentence: "", sentence_en: "", candidates: [], unit: "" }] })
    setForm(newForm)
  }

  const updateCategory = (value: { category?: string, category_en?: string }, index: number) => {
    const newForm: typeof form = JSON.parse(JSON.stringify(form)) // Json object を deep copy する
    if (value.category !== undefined) {
      newForm[index].category = value.category
    }
    if (value.category_en !== undefined) {
      newForm[index].category_en = value.category_en
    }
    setForm(newForm)
  }

  const updateQuestions = (questions: QuestionEdit[], index: number) => {
    const newForm: typeof form = JSON.parse(JSON.stringify(form)) // Json object を deep copy する
    newForm[index].questions = questions
    setForm(newForm)
  }

  const deleteCategory = (index: number) => {
    const newForm: typeof form = JSON.parse(JSON.stringify(form)) // Json object を deep copy する
    newForm.splice(index, 1)
    setForm(newForm)
  }

  const handleClick = () => {
    if (validateForm(form)) {
      setValidated(true)
    } else {
      setValidated(false)
      // validated でない場合はチェック項目を更新しない。
      return
    }

    const endpoint = `/applications/form/update?before_driving=${beforeDriving}`
    axios.post(endpoint, form)
      .then(_ => {
        success("チェック項目を更新しました。", "Check items has been updated.")
      })
      .catch(err => {
        error(
          err.response?.data?.detail?.messageJa || `${text.message.unexpected_error.ja} ${err.message}`,
          err.response?.data?.detail?.messageEn || `${text.message.unexpected_error.en} ${err.message}`
        )
      })
  }

  return (
    <div style={{ margin: 20 }}>
      <Typography variant="h5">{canDisplayJapanese() ? "チェック項目編集(Edit Check Items)" : "Edit Check Items"}</Typography>
      <TextField style={{ width: 350, marginRight: 8 }} variant="outlined" select fullWidth SelectProps={{ native: true }}
        onChange={(e) => setBeforeDriving(e.target.value === "true")}>
        <option value="true">{text.title.before.title + '(' + text.title.before.title_en + ')'}</option>
        <option value="false">{text.title.after.title + '(' + text.title.after.title_en + ')'}</option>
      </TextField>
      <Button className={classes.button} variant="contained" color="primary" onClick={handleClick}>Update</Button>
      {form.map((group, index) => (
        <Paper key={group.key} className={classes.paper}>
          <CheckCategory category={group.category} category_en={group.category_en} only={form.length === 1} validated={validated}
            onAdd={() => addCategory(index)}
            onUpdate={(value: { category?: string, category_en?: string }) => updateCategory(value, index)}
            onDelete={() => deleteCategory(index)} />
          <CheckQuestions questions={group.questions} validated={validated}
            onUpdate={(questions: QuestionEdit[]) => updateQuestions(questions, index)} />
        </Paper>
      ))}
    </div>
  )
}

type CategoryProps = {
  category: string
  category_en: string
  only: boolean
  validated: boolean
  onAdd: () => void
  onUpdate: (value: { category?: string, category_en?: string }) => void
  onDelete: () => void
}

/**
 * カテゴリ項目編集要素
 * @param category カテゴリ（日本語）
 * @param category_en カテゴリ（英語）
 * @param onAdd 追加ボタン押下時のコールバック関数
 * @param onUpdate 項目編集時のコールバック関数
 * @param onDelete 削除ボタン押下時のコールバック関数
 * @param only true の場合、削除ボタンが押下できない。すべてのカテゴリを削除してしまうことがないようにする。
 * @param validated false の場合、空欄の項目をエラーとする。
 */
const CheckCategory: React.FC<CategoryProps> = ({ category, category_en, onAdd, onUpdate, onDelete, only, validated }) => {
  const classes = useStyles()

  const handleCategoryChange = (category: string) => {
    onUpdate({ category: category })
  }

  const handleCategoryEnChange = (category_en: string) => {
    onUpdate({ category_en: category_en })
  }

  return (
    <Grid container spacing={1} className={classes.category}>
      <Grid item>
        <TextField variant="outlined" label={canDisplayJapanese() ? "カテゴリー" : "category(ja)"} value={category}
          onChange={(e) => handleCategoryChange(e.target.value)}
          error={!validated && category === ""} />
      </Grid>
      <Grid item>
        <TextField variant="outlined" label={canDisplayJapanese() ? "category" : "category(en)"} value={category_en}
          onChange={(e) => handleCategoryEnChange(e.target.value)}
          error={!validated && category_en === ""} />
      </Grid>
      <Grid item>
        <Button className={classes.button} variant="contained" color="primary" onClick={onAdd}>Add Category</Button>
        <Button className={classes.button} variant="contained" color="primary" onClick={onDelete} disabled={only}>Remove Category</Button>
      </Grid>
    </Grid>
  )
}

type QuestionsProps = {
  questions: QuestionEdit[]
  validated: boolean
  onUpdate: (questions: QuestionEdit[]) => void
}

/**
 * ひとつのカテゴリに属する質問をまとめて管理するコンポーネント
 * @param questions 質問オブジェクトの配列
 * @param onUpdate 質問更新時のコールバック関数
 * @param validated false の場合、空欄の項目をエラーとする。
 */
const CheckQuestions: React.FC<QuestionsProps> = ({ questions, onUpdate, validated }) => {
  const addQuestion = (index: number) => {
    const newQuestions: typeof questions = JSON.parse(JSON.stringify(questions)) // deep copy
    newQuestions.splice(index + 1, 0, { key: v4(), sentence: "", sentence_en: "", candidates: [], unit: "" })
    onUpdate(newQuestions)
  }

  const updateQuestion = (value: Partial<QuestionType>, index: number) => {
    const newQuestions: typeof questions = JSON.parse(JSON.stringify(questions)) // deep copy
    if (value.sentence !== undefined) {
      newQuestions[index].sentence = value.sentence
    }
    if (value.sentence_en !== undefined) {
      newQuestions[index].sentence_en = value.sentence_en
    }
    if (value.candidates !== undefined) {
      newQuestions[index].candidates = value.candidates
    }
    if (value.unit !== undefined) {
      newQuestions[index].unit = value.unit
    }
    onUpdate(newQuestions)
  }

  const deleteQuestion = (index: number) => {
    const newQuestions: typeof questions = JSON.parse(JSON.stringify(questions)) // deep copy
    newQuestions.splice(index, 1)
    onUpdate(newQuestions)
  }

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              {canDisplayJapanese() && <div>確認内容</div>}
              <div>Question</div>
            </TableCell>
            <TableCell>
              {canDisplayJapanese() && <div>選択リスト</div>}
              <div>Options</div>
            </TableCell>
            <TableCell>
              {canDisplayJapanese() && <div>単位</div>}
              <div>Unit</div>
            </TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {questions.map((question, index) => (
            <CheckQuestion key={question.key} only={questions.length === 1} validated={validated}
              sentence={question.sentence} sentence_en={question.sentence_en} candidates={question.candidates} unit={question.unit}
              onAdd={() => addQuestion(index)}
              onUpdate={value => updateQuestion(value, index)}
              onDelete={() => deleteQuestion(index)} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

type CheckQuestionProps = QuestionType & {
  onAdd: () => void
  onUpdate: (value: Partial<QuestionType>) => void
  onDelete: () => void
  only: boolean
  validated: boolean
}

/**
 * 質問要素
 * @param sentence 質問文（日本語）
 * @param sentence_en 質問文（英語）
 * @param candidates 回答候補
 * @param unit 単位
 * @param onAdd 追加ボタン押下時のコールバック関数
 * @param onUpdate 項目編集時のコールバック関数
 * @param onDelete 削除ボタン押下時のコールバック関数
 * @param only true の場合、削除ボタンが押下できない。すべての質問を削除してしまうことがないようにする。
 * @param validated false の場合、空欄の項目をエラーとする。
 */
const CheckQuestion: React.FC<CheckQuestionProps> = ({ sentence, sentence_en, candidates, unit, onAdd, onUpdate, onDelete, only, validated }) => {
  const classes = useStyles()

  return (
    <TableRow>
      <TableCell>
        <TextField className={classes.sentence} variant="outlined" size="small" fullWidth label={canDisplayJapanese() ? "日本語(Japanese)" : "Japanese"} 
          value={sentence} onChange={(e) => onUpdate({ sentence: e.target.value })}
          error={!validated && sentence === ""} /><br />
        <TextField className={classes.sentence} variant="outlined" size="small" fullWidth label={canDisplayJapanese() ? "英語(English)" : "English"} 
          value={sentence_en} onChange={(e) => onUpdate({ sentence_en: e.target.value })}
          error={!validated && sentence_en === ""} />
      </TableCell>
      <TableCell>
        <ChipInput variant="outlined" fullWidth
          value={candidates} placeholder={canDisplayJapanese () ? "空欄の場合は自由記入(Free description if blank)" : "Free description if blank"}
          onAdd={(candidate) => onUpdate({ candidates: [...candidates, candidate] })}
          onDelete={(candidate, _) => onUpdate({ candidates: candidates.filter(e => e !== candidate) })} />
      </TableCell>
      <TableCell>
        <TextField variant="outlined"
          value={unit} onChange={(e) => onUpdate({ unit: e.target.value })} />
      </TableCell>
      <TableCell>
        <Button variant="contained" color="primary" className={classes.tableButton} onClick={onAdd}>
          Add
        </Button>
        <Button variant="contained" color="primary" className={classes.tableButton} onClick={onDelete} disabled={only}>
          Remove
        </Button>
      </TableCell>
    </TableRow>
  )
}
