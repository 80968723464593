import {
  AppBar,
  Box,
  Button,
  createStyles,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  makeStyles,
  Menu, MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Theme,
  Toolbar,
  Typography
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import React, { useEffect } from "react";
import { useAuthenticatedUser, useLogout } from "../AuthenticationContext";
import { ChangePasswordDialog } from "./account";
import { formatISO8601 } from "../common";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useNotificationDispatch } from "../notification";
import { Notification } from "../notification";
import { canDisplayJapanese, text } from "../localize";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    toolbar: {
      paddingLeft: 0,
    },
    title: {
      flexGrow: 1,
    },
    content: {
      paddingTop: theme.spacing(6),
      paddingRight: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(2),
    },
    paper: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      padding: theme.spacing(2)
    },
    menu: {
      marginTop: theme.spacing(5),
    }
  })
)

/**
 * チェック申請画面
 */
export const CheckPage: React.FC = () => {
  const classes = useStyles()
  const [changePasswordUser, setChangePasswordUser] = React.useState<string | null>(null)
  const [candidateMobilities, setCandidateMobilities] = React.useState<string[]>([])
  const [projectId, setProjectId] = React.useState<string>("")
  const [mobilityNumber, setMobilityNumber] = React.useState<string | null>(null)
  const [beforeDriving, setBeforeDriving] = React.useState<boolean>(true)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  // 現地時刻を初期値とする。
  const nowDate = formatISO8601(new Date(), (new Date()).getTimezoneOffset())
  const [appliedDate, setAppliedDate] = React.useState(nowDate.slice(0, 16))

  // チェック項目申請フォームの表示状態
  const [display, setDisplay] = React.useState(false)

  // 申請完了の場合、true
  const [completed, setCompleted] = React.useState(false)

  const logout = useLogout()
  const user = useAuthenticatedUser()
  const { error } = useNotificationDispatch()
  const open = Boolean(anchorEl);

  const handleOKClick = (mobilityNumber: string) => {
    axios.get("/applications/beforeDriving", {
      params: {
        projectId: projectId,
        mobilityNumber: mobilityNumber
      }
    }).then(response => {
      setBeforeDriving(response.data)
      setDisplay(true)
    }).catch(err => {
      error(
        err.response?.data?.detail?.messageJa || `${text.message.unexpected_error.ja} ${err.message}`,
        err.response?.data?.detail?.messageEn || `${text.message.unexpected_error.en} ${err.message}`
      )
    })
  }

  const handleCancelClick = React.useCallback(() => {
    setDisplay(false)
    setMobilityNumber(null)
  }, [])

  useEffect(() => {
    axios.get("/projects/mobilities", {
      params: {
        currentTime: (new Date()).getTime() / 1000 - ((new Date()).getTimezoneOffset() * 60)
      }
    })
      .then(response => {
        setCandidateMobilities(response.data.mobilities)
        setProjectId(response.data.projectId)
      })
      .catch(err => {
        error(
          err.response?.data?.detail?.messageJa || `${text.message.unexpected_error.ja} ${err.message}`,
          err.response?.data?.detail?.messageEn || `${text.message.unexpected_error.en} ${err.message}`
        )
      })
  }, [error])

  const handleChange = (value: string) => {
    setAppliedDate(value)
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={classes.root}>
      <AppBar position="fixed">
        <Toolbar classes={{ root: classes.toolbar }}>
          <Typography className={classes.title} />
          <Box>
            <IconButton id="menu-button" color="inherit" onClick={handleClick}><FontAwesomeIcon icon={faBars} /></IconButton>
            <Menu className={classes.menu} id="menu" anchorEl={anchorEl} open={open} onClose={handleClose} anchorOrigin={{vertical: 'bottom', horizontal: 'right'}} transformOrigin={{vertical: 'top', horizontal: 'right'}}>
              <MenuItem onClick={handleClose}>
                <Link underline="none" href={`${process.env.PUBLIC_URL}/manual/manual-ja.html`} target="_blank">マニュアル (日本語)<FontAwesomeIcon icon={faExternalLinkAlt} style={{ marginLeft: "0.5em" }}/></Link>
              </MenuItem>
              <MenuItem onClick={handleClose}>
                <Link underline="none" href={`${process.env.PUBLIC_URL}/manual/manual-en.html`} target="_blank">Manual (English)<FontAwesomeIcon icon={faExternalLinkAlt} style={{ marginLeft: "0.5em" }}/></Link>
              </MenuItem>
              <MenuItem onClick={() => {handleClose(); setChangePasswordUser(user?.userId ?? null);}}>Change Password</MenuItem>
              <MenuItem onClick={() => {handleClose() ;logout();}}>Logout</MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
      <main className={classes.content}>
        <Paper className={classes.paper}>
          <TextField
            value={projectId}
            fullWidth
            variant={"outlined"}
            label={"Project ID"}
            style={{ marginTop: 16 }}
            InputLabelProps={{ shrink: true }}
            disabled={true} />
          <TextField
            value={user?.userId ?? null}
            fullWidth
            variant={"outlined"}
            label={"Reported by"}
            style={{ marginTop: 16 }}
            InputLabelProps={{ shrink: true }}
            disabled={true} />
          <div style={{ width: '100%', display: "flex", alignItems: "center" }}>
            <div style={{ flex: 1 }}>
              <Autocomplete
                value={mobilityNumber}
                options={candidateMobilities}
                style={{ width: "auto", marginTop: 16, marginRight: 16 }}
                onChange={(e, value) => setMobilityNumber(value)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    variant="outlined"
                    label="vehicle number" />
                )} />
            </div>
            <div style={{ marginTop: 16 }}>
              {display
                ? <Button color={"primary"} variant={"contained"} onClick={handleCancelClick}>Cancel</Button>
                : <Button color={"primary"} variant={"contained"} disabled={!mobilityNumber} onClick={() => handleOKClick(mobilityNumber!)}>OK</Button>}
            </div>
          </div>
          <TextField
            type="datetime-local"
            variant={"outlined"}
            defaultValue={appliedDate}
            style={{ marginTop: 16 }}
            fullWidth
            label={"Date / time"}
            InputLabelProps={{ shrink: true }}
            onChange={(e) => handleChange(e.target.value)} />
        </Paper>
        {display && <ApplicationForm beforeDriving={beforeDriving} setBeforeDriving={setBeforeDriving} projectId={projectId} mobilityNumber={mobilityNumber!} appliedDate={appliedDate} setDisplay={setDisplay} setCompleted={setCompleted} />}
        {completed && <ApplicationCompleted />}
      </main>
      <Notification />
      <ChangePasswordDialog user={changePasswordUser} close={() => setChangePasswordUser(null)} />
    </div>
  )
}

type ApplicationFormProps = {
  beforeDriving: boolean | null
  setBeforeDriving: (isBefore: boolean) => void
  projectId: string
  mobilityNumber: string
  appliedDate: string
  setDisplay: (display: boolean) => void
  setCompleted: (completed: boolean) => void
}

export type QuestionGroupType = {
  category: string
  category_en: string
  questions: QuestionType[]
}

export type QuestionType = {
  sentence: string
  sentence_en: string
  candidates: string[]
  unit: string
}

/**
 * チェック申請フォーム
 * @param beforeDriving 走行前チェックかどうか
 * @param setBeforeDriving 走行前チェックと走行後チェックを切り替える関数
 * @param projectId
 * @param mobilityNumber
 * @param appliedDate 申告時刻 (初期値は現在時刻とする。手入力することも可能。サーバー時刻とは別に管理される。)
 * @param setDisplay チェック申請フォームの表示・非表示を切り替える関数
 * @param setCompleted 申請完了画面に切り替える関数
 */
const ApplicationForm: React.FC<ApplicationFormProps> = ({ beforeDriving, setBeforeDriving, projectId, mobilityNumber, appliedDate, setDisplay, setCompleted }) => {
  const classes = useStyles()
  const [form, setForm] = React.useState<QuestionGroupType[]>()
  const [formId, setFormId] = React.useState("")
  const [validated, setValidated] = React.useState(true)

  // 申請者の回答を順に flat な配列に保持する。申請フォームのカテゴリなどの区分はないことに留意する。
  // 配列の長さは form の questions の合計数に一致することを期待する。
  const [answers, setAnswers] = React.useState<{ number: number, answer: string }[]>([])

  const { error } = useNotificationDispatch()

  useEffect(() => {
    axios.get(`/applications/form/${beforeDriving ? "before" : "after"}`)
      .then(res => {
        setFormId(res.data.formId)
        setForm(res.data.groups)
      }).catch(err =>
        error(
          err.response?.data?.detail?.messageJa || `${text.message.unexpected_error.ja} ${err.message}`,
          err.response?.data?.detail?.messageEn || `${text.message.unexpected_error.en} ${err.message}`
        )
      )
  }, [beforeDriving, error])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBeforeDriving(event.currentTarget.value === "true")
    setAnswers([])
    setValidated(true)
  }

  const isRequired = (question: QuestionType) => {
    return !(question.candidates.length === 0 && question.unit === "")
  }

  const handleSubmit = async () => {
    // 回答が必須か否かを表す真偽値を flat な配列に保持する
    const areRequied = !form ? [] : form.map(questionGroup => questionGroup.questions.map(question => isRequired(question))).flat()
    const submitAnswers = answers.sort((a, b) => a.number - b.number).map(answer => answer.answer)

    //未選択の項目がある場合は申請しない。
    if (submitAnswers.length < questionNumber) {
      setValidated(false)
      return
    } else {
      if (submitAnswers.some((answer, index) => areRequied[index] && answer === "")) {
        setValidated(false)
        return
      }
      setValidated(true)
    }

    axios.post("/applications/apply", {
      formId: formId,
      mobilityNumber: mobilityNumber,
      projectId: projectId,
      answers: submitAnswers,
      appliedDate: (new Date(appliedDate)).getTime() / 1000,
      tzOffset: (new Date(appliedDate)).getTimezoneOffset()
    })
      .then(_ => {
        setDisplay(false)
        setCompleted(true)
      })
      .catch(err => {
        error(
          err.response?.data?.detail?.messageJa || `${text.message.unexpected_error.ja} ${err.message}`,
          err.response?.data?.detail?.messageEn || `${text.message.unexpected_error.en} ${err.message}`
        )
      })
  }

  // 申請フォームの質問ひとつずつにユニークな通し番号(カテゴリなどの区分を横断する)として利用する。
  let questionNumber = 0

  return (
    <div>
      <Grid container>
        <Grid item>
          <Typography variant="h5" style={{ marginRight: 16 }}>
            {canDisplayJapanese() && <div>{beforeDriving ? text.title.before.title : text.title.after.title}</div>}
            <div>{beforeDriving ? text.title.before.title_en : text.title.after.title_en}</div>
          </Typography>
        </Grid>
        <Grid item>
          <FormControl>
            <RadioGroup value={beforeDriving} onChange={handleChange}>
              <FormControlLabel value={true} control={<Radio />} label={canDisplayJapanese() ? text.title.before.title + "(" + text.title.before.title_en + ")" : text.title.before.title_en} />
              <FormControlLabel value={false} control={<Radio />} label={canDisplayJapanese() ? text.title.after.title + "(" + text.title.after.title_en + ")" : text.title.after.title_en} />
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
      <Typography variant="subtitle1" style={{ fontSize: "0.85rem", marginTop: 16 }}>※ 「NO」と判断された場合、日報に記載してください</Typography>
      <Typography variant="subtitle1" style={{ fontSize: "0.85rem" }}>* If you answer "NO" , Pls record it on dairy report.</Typography>
      <Typography variant="subtitle1" style={{ fontSize: "0.85rem", marginTop: 16 }}>結果 : 良好、はい -&gt; 'OK'、 異常、いいえ -&gt; 'NO'</Typography>
      <Typography variant="subtitle1" style={{ fontSize: "0.85rem" }}>Result : no problem, yes -&gt; 'OK', problem, no -&gt; 'NO'</Typography>
      {form && form.map((value, index) => {
        return (
          <Paper key={index} className={classes.paper}>
            <Grid container>
              <Grid item>
                <Typography variant="h6">
                  {canDisplayJapanese() && <div>{value.category}</div>}
                  <div>{value.category_en}</div>
                </Typography>
              </Grid>
            </Grid>
            <TableContainer>
              <Table>
                <TableBody>
                  {value.questions.map((question, _) => {
                    // 質問を数え上げることで、answers 配列の何番目を更新すればよいか分かる。
                    questionNumber++
                    return (
                      <ApplicationQuestion key={`${questionNumber}.${question.sentence}`} questionNumber={questionNumber} sentence={question.sentence} sentence_en={question.sentence_en}
                        candidates={question.candidates} unit={question.unit} answers={answers} setAnswers={setAnswers} validated={validated} />
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        )
      })}
      <Button fullWidth variant="contained" color="primary" onClick={handleSubmit} disabled={!mobilityNumber}>submit</Button>
    </div>
  )
}

type ApplicationQuestionProps = {
  questionNumber: number
  sentence: string
  sentence_en: string
  candidates: string[]
  unit: string
  answers: { number: number, answer: string }[]
  setAnswers: (answers: { number: number, answer: string }[]) => void
  validated: boolean
}

/**
 * チェック申請フォームのひとつの質問
 * @param questionNumber 通し番号
 * @param sentence 質問（日本語）
 * @param sentence_en 質問（英語）
 * @param candidates 回答候補
 * @param unit 単位
 * @param answers 全ての回答を保持する配列
 * @param setAnswers 回答を保持する配列を更新する関数
 * @param validated false の場合、空欄の項目をエラーとする。
 */
const ApplicationQuestion: React.FC<ApplicationQuestionProps> = ({ questionNumber, sentence, sentence_en, candidates, unit, answers, setAnswers, validated }) => {
  const [input, setInput] = React.useState("")

  useEffect(() => {
    answers.push({ number: questionNumber, answer: '' })
  }, [])
  
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInput(event.currentTarget.value)
    const answer = { number: Number(event.currentTarget.name), answer: event.currentTarget.value }
    // 既に回答があれば新しい回答に置き換える。回答がなければ追加する。
    const num = answers.findIndex(e => e.number === answer.number)
    if (num === -1) {
      answers.push(answer)
    } else {
      answers.splice(num, 1, answer)
    }
    setAnswers([...answers])
  }

  // 未回答の項目があれば true を返す。（エラーとして扱う。）
  const isError = (number: number) => {
    const num = answers.findIndex(e => e.number === number)
    if (num === -1){
      return true
    } else if (answers[num].answer === "") {
      return true
    }
    return false
  }

  return (
    <TableRow>
      <TableCell style={{width: "100%"}}>
        {canDisplayJapanese() && <div>{sentence}</div>}
        <div>{sentence_en}</div>
      </TableCell>
      <TableCell style={{ minWidth: 120 }}>
        {candidates.length > 0
          ? <TextField
            value={input} variant={"outlined"} select fullWidth SelectProps={{ native: true }}
            name={questionNumber.toString()}
            onChange={handleInputChange}
            error={validated ? false : isError(questionNumber)}
            helperText={!validated && isError(questionNumber) ? "This field is required." : ""}>
            <option value="" />
            {candidates.map(value => (<option key={value} value={value}>{value}</option>))}
          </TextField>
          // 回答候補がない場合は自由記入欄として扱う。
          : (unit === "" ? 
              // 単位がない場合は複数行入力可とする
              <TextField
              multiline rows={4}
              value={input} variant={"outlined"} fullWidth
              name={questionNumber.toString()}
              onChange={handleInputChange} /> 
              :
              <TextField
              inputMode="decimal"
              InputProps={{ endAdornment: <InputAdornment position="end">{unit}</InputAdornment>}}
              value={input} variant={"outlined"} fullWidth
              name={questionNumber.toString()}
              onChange={handleInputChange}
              error={validated ? false : isError(questionNumber)}
              helperText={!validated && isError(questionNumber) ? "This field is required." : ""} />
            )
        }
      </TableCell>
    </TableRow >
  )
}

/**
 * 申請完了後に表示するコンポーネント
 */
const ApplicationCompleted: React.FC = () => {
  const classes = useStyles()

  const logout = useLogout()

  return (
    <div className={classes.paper}>
      <Grid container spacing={3}>
        <Grid item>
          <Typography variant="h5">
            {canDisplayJapanese() && <div>{text.complete_message.sentence}</div>}
            <div>{text.complete_message.sentence_en}</div>
          </Typography>
        </Grid>
      </Grid>
      <Button color="primary" variant="contained" onClick={() => logout()}>logout</Button>
    </div>
  )
}
