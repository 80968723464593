import React from "react";
import {Snackbar} from "@material-ui/core";
import {Alert, AlertTitle} from "@material-ui/lab";


export type State = SuccessState | InformationState | WarningState | ErrorState | NotShowing

type NotShowing = {
  isShow: false
}

type Showing = {
  messageJa: string,
  messageEn: string,
  isShow: true
}

type SuccessState = Showing & {
  level: "Success"
}

type InformationState = Showing & {
  level: "Info"
}

type WarningState = Showing & {
  level: "Warning"
}

type ErrorState = Showing & {
  level: "Error"
}

export type NotificationDispatchType = {
  success: (messageJa: string, messageEn: string) => void
  info: (messageJa: string, messageEn: string) => void
  warn: (messageJa: string, messageEn: string) => void
  error: (messageJa: string, messageEn: string) => void
  hide: () => void
}

const NotificationStateContext = React.createContext<State>({isShow: false})
const NotificationDispatchContext = React.createContext<NotificationDispatchType>({
  success: (_) => console.error("Not Impl."),
  info: (_) => console.error("Not Impl."),
  warn: (_) => console.error("Not Impl."),
  error: (_) => console.error("Not Impl."),
  hide: () => console.error("Not Impl.")
})

/**
 * 通知ポップアップの状態を保持するコンテキストプロバイダ
 * プロバイダー配下のコンポーネントでは、通知ポップアップのコントロールを共有できる。
 * @param children
 */
export const NotificationProvider: React.FC = ({children}) => {
  const [state, setState] = React.useState<State>({isShow: false})

  // message を 成功 として表示する。
  const success = React.useCallback((messageJa: string, messageEn: string) => {
    setState({messageJa: messageJa, messageEn: messageEn, isShow: true, level: "Success"})
  }, [])

  // message を 情報 として表示する。
  const info = React.useCallback((messageJa: string, messageEn: string) => {
    setState({messageJa: messageJa, messageEn: messageEn, isShow: true, level: "Info"})
  }, [])

  // message を 警告 として表示する。
  const warn = React.useCallback((messageJa: string, messageEn: string) => {
    setState({messageJa: messageJa, messageEn: messageEn, isShow: true, level: "Warning"})
  }, [])

  // message を エラー として表示する。
  const error = React.useCallback((messageJa: string, messageEn: string) => {
    setState({messageJa: messageJa, messageEn: messageEn, isShow: true, level: "Error"})
  }, [])

  const hide = React.useCallback(() => {
    setState({isShow: false})
  }, [])

  return (
    <NotificationDispatchContext.Provider value={{ success, info, warn, error, hide }}>
      <NotificationStateContext.Provider value={ state }>
        {children}
      </NotificationStateContext.Provider>
    </NotificationDispatchContext.Provider>
  )
}

export const useNotificationDispatch = () => React.useContext(NotificationDispatchContext)
export const useNotificationState = () => React.useContext(NotificationStateContext)

/**
 * Notification State に応じて通知ポップアップをレンダリングする。
 * NotificationProvider 配下に配置されることを期待する。
 */
export const Notification: React.FC = () => {
  const state = useNotificationState()
  const { hide } = useNotificationDispatch()

  if (state.isShow) {
    const severity =
      state.level === "Success" ? "success" :
        state.level === "Warning" ? "warning" :
          state.level === "Error" ? "error" : "info"
    return (
      <Snackbar open={state.isShow} autoHideDuration={6000} onClose={hide}>
        <Alert elevation={3} onClose={hide} severity={severity}>
          <AlertTitle>{state.level}</AlertTitle>
          <div>{state.messageJa}</div>
          <div>{state.messageEn}</div>
        </Alert>
      </Snackbar>
    )
  } else {
    return <noscript />
  }
}
