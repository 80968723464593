import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, InputAdornment,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow, TextField,
  Typography
} from "@material-ui/core";
import axios from "../http";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNotificationDispatch } from "../notification";
import { formatISO8601 } from "../common";
import { FilterMenu } from "./common";
import { canDisplayJapanese, text } from "../localize";

const useStyles_Table = makeStyles({
  table: {
    minWidth: 450,
  },
  container: {
    marginTop: 20,
    maxHeight: 'calc(100vh - 204px)',
    minHeight: 172
  }
})

type ItemType = {
  userId: string,
  role: string,
  company: string,
  email: string,
  registeredDate: number
  tzOffset: number
}

/**
 * ユーザーアカウント一覧画面
 */
export const ManageAccount: React.FC = () => {
  const classes = useStyles_Table()
  const [isLoaded, setIsLoaded] = useState(false)
  const [items, setItems] = useState<ItemType[]>([])

  // チェックボックスが押下されている userId を格納する。
  const [check, setCheck] = useState<string[]>([])

  // フィルターする 会社名 を格納する。null の場合はフィルターしない。
  const [filtered, setFiltered] = useState<string | null>(null)

  // アカウント情報を編集する userId を格納する。新規作成の場合は空文字列を格納する。null の場合はアカウント編集ダイアログを表示しない。
  const [editUser, setEditUser] = React.useState<string | null>(null)
  const { success, error } = useNotificationDispatch()

  const handleCheck = (checked: boolean, name: string) => {
    setCheck(checked ? [...check, name] : check.filter(e => e !== name))
  }

  const openCreateUserDialog = () => {
    setEditUser("")
  }

  const openUpdateUserDialog = (user_id: string) => {
    setEditUser(user_id)
  }

  const deleteAccounts = async () => {
    axios.post("/users/delete", {
      userIds: check,
    })
      .then(_ => {
        success("ユーザーを削除しました。", "User has been deleted.")
        setCheck([])
        refresh()
      })
      .catch(err => {
        error(
          err.response?.data?.detail?.messageJa || `${text.message.unexpected_error.ja} ${err.message}`,
          err.response?.data?.detail?.messageEn || `${text.message.unexpected_error.en} ${err.message}`
        )
      })
  }

  const refresh = React.useCallback(() => {
    axios.get("/users/")
      .then(res => {
        setItems(res.data)
        setIsLoaded(true)
      })
      .catch(err => {
        error(
          err.response?.data?.detail?.messageJa || `${text.message.unexpected_error.ja} ${err.message}`,
          err.response?.data?.detail?.messageEn || `${text.message.unexpected_error.en} ${err.message}`
        )
      })
  }, [error])

  useEffect(() => {
    refresh()
  }, [refresh])

  if (!isLoaded) {
    return <div>Loading...</div>
  } else {
    return (
      <div style={{ margin: 20 }}>
        <Typography variant="h5">{canDisplayJapanese() ? "ユーザーアカウント一覧(Accounts)" : "Accounts"}</Typography>
        <Grid container justify={"space-between"}>
          <Grid item>
            <Button variant="contained" color="primary" style={{ marginTop: 10 }} onClick={openCreateUserDialog}>Create</Button>
            <FilterMenu
              name={"Company Name"} selected={filtered}
              setSelected={(selected: string | null) => setFiltered(selected)}
              candidates={items.reduce((a: string[], b) => {
                // ユニークな会社名のリストを生成する。
                if (!a.includes(b.company)) {
                  a.push(b.company)
                }
                return a
              }, [])
              } />
          </Grid>
          <Button variant="contained" color="secondary" disabled={check.length === 0} style={{ marginTop: 10 }} onClick={() => deleteAccounts()}>Delete</Button>
        </Grid>
        <TableContainer component={Paper} className={classes.container}>
          <Table stickyHeader className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>
                  {canDisplayJapanese() && <div>選択</div>}
                  <div>Select</div>
                </TableCell>
                <TableCell>
                  {canDisplayJapanese() && <div>ユーザーID</div>}
                  <div>User ID</div>
                </TableCell>
                <TableCell>
                  {canDisplayJapanese() && <div>種別</div>}
                  <div>Role</div>
                </TableCell>
                <TableCell>
                  {canDisplayJapanese() && <div>会社名</div>}
                  <div>Company</div>
                </TableCell>
                <TableCell>
                  {canDisplayJapanese() && <div>メールアドレス</div>}
                  <div>Mail Address</div>
                </TableCell>
                <TableCell>
                  {canDisplayJapanese() && <div>登録年月日</div>}
                  <div>Registered Date</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {// 会社名フィルター
                items.filter(e => !filtered || e.company === filtered).map(row => (
                  <TableRow key={row.userId}>
                    <TableCell><Checkbox checked={check.includes(row.userId)} onChange={(e) => handleCheck(e.target.checked, e.target.name)} name={row.userId} /></TableCell>
                    <TableCell><Button color={"primary"} style={{ textTransform: 'none' }} onClick={() => openUpdateUserDialog(row.userId)}>{row.userId}</Button></TableCell>
                    <TableCell>
                      {row.role === "manager" ? (canDisplayJapanese() ? "管理者(Manager)" : "Manager") 
                      : row.role === "comanager" ? (canDisplayJapanese() ? "委託先管理者(Comanager)" : "Coamanger") 
                      : row.role === "tester" ? (canDisplayJapanese() ? "計測者(Tester)" : "Tester")
                      : (canDisplayJapanese() ? "運転者(Driver)" : "Driver")}
                    </TableCell>
                    <TableCell>{row.company}</TableCell>
                    <TableCell>{row.email}</TableCell>
                    <TableCell>{
                      // サーバーからはUTCエポック[秒]とオフセット[分]を受信する
                      // UTCエポック秒からjsのDate型に変換し、それを画面表示用にフォーマットする。
                      // ※ただし、js のDate型は、UTCエポック秒を渡してもローカルタイムとしてインスタンス化するため、ローカルのTZ offsetをオフセットしてnew Dateのコンストラクタに渡す
                      formatISO8601(new Date(row.registeredDate * 1000 - (row.tzOffset * 60000) + ((new Date().getTimezoneOffset() * 60000))), row.tzOffset)}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <CreateAccountDialog open={editUser} close={() => setEditUser(null)} refresh={refresh} />
      </div>
    )
  }
}

const useStyles_Create = makeStyles({
  input: {
    margin: 8,
  },
})

/**
 * アカウント編集ダイアログ
 * @param open アカウント情報を編集する userId を格納する。新規作成の場合は空文字列を格納する。null の場合は編集しない。
 * @param close アカウント編集ダイアログを閉じる関数
 * @param refresh アカウント編集後に、アカウント一覧画面を更新するための関数
 */
const CreateAccountDialog: React.FC<{ open: string | null, close: () => void, refresh: () => void }> = ({ open, close, refresh }) => {
  const classes = useStyles_Create()
  const [userId, setUserId] = useState<string>("")
  const [password, setPassword] = useState<string>("")
  const [role, setRole] = useState("driver")
  const [company, setCompany] = useState<string>("")
  const [email, setEmail] = useState<string>("")
  const roles = [
    { value: "manager", labelJa: "管理者(Manager)", labelEn: "Manager" },
    { value: "comanager", labelJa: "委託先管理者(Comanager)", labelEn: "Comanager" },
    { value: "tester", labelJa: "計測者(Tester)", labelEn: "Tester" },
    { value: "driver", labelJa: "運転者(Driver)", labelEn: "Driver" },
  ]
  const [showPassword, setShowPassword] = React.useState<boolean>(false)
  const toggleShowPassword = () => setShowPassword(!showPassword)
  const create = open === ""
  const { success, error } = useNotificationDispatch()
  const [validated, setValidated] = React.useState(true)

  useEffect(() => {
    setValidated(false)
    // 編集時は現在の値を取得して初期値とする。
    if (!create && !!open) {
      axios.get("/users/get", {
        params: {
          user_id: open
        }
      })
        .then(res => {
          setUserId(open)
          setPassword("")
          setRole(res.data.role)
          setCompany(res.data.company)
          setEmail(res.data.email)
        })
        .catch(err => {
          error(
            err.response?.data?.detail?.messageJa || `${text.message.unexpected_error.ja} ${err.message}`,
            err.response?.data?.detail?.messageEn || `${text.message.unexpected_error.en} ${err.message}`
          )
          close()
          refresh()
        })
    } else {
      // 新規作成時は初期値にする。
      setUserId("")
      setPassword("")
      setRole("driver")
      setCompany("")
      setEmail("")
    }
  }, [create, open, error])

  const executeRegistry = async () => {
    setValidated(true)

    // 必須項目が空欄の場合はエラーとする。
    if (userId === "" || (create && password === "") || company === "" || (["manager", "comanager"].includes(role) && email === "")) {
      return
    }

    const endpoint = create ? "/users/create" : "/users/update"
    axios.post(endpoint, {
      userId: userId,
      password: password,
      role: role,
      company: company,
      email: email,
      tzOffset: (new Date()).getTimezoneOffset()
    })
      .then(_ => {
        refresh()
        close()
        success(`${userId} を${create ? "追加" : "更新"}しました。`, `${userId} has been ${create ? "created" : "updated"}.`)
        setValidated(false)
      })
      .catch(err => {
        error(
          err.response?.data?.detail?.messageJa || `${text.message.unexpected_error.ja} ${err.message}`,
          err.response?.data?.detail?.messageEn || `${text.message.unexpected_error.en} ${err.message}`
        )
      })
  }

  return (
    <Dialog open={create || !!open} onClose={close} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">
        {create ? (canDisplayJapanese() ? "アカウント追加(Create account)" : "Create account") 
        : (canDisplayJapanese() ? "アカウント更新(Update account)" : "Update account")}
      </DialogTitle>

      <DialogContent>
        <DialogContentText>
          {create ?  (canDisplayJapanese() ? "新しいアカウントを追加します。(Create a new account.)" : "Create a new account.") 
          : (canDisplayJapanese() ? "アカウントを更新します。(Update an existing account.)" : "Update an existing account.")}
        </DialogContentText>
        <TextField required error={validated && !userId} variant="outlined" fullWidth className={classes.input} label={canDisplayJapanese() ? "ユーザーID(User ID)" : "User ID"} value={userId} disabled={!create}
          onChange={(e) => setUserId(e.target.value)} />
        <TextField
          required error={validated && !password && create}
          variant="outlined" fullWidth className={classes.input} label={canDisplayJapanese() ? "パスワード(Password)" : "Password"}
          placeholder={create ? (canDisplayJapanese() ? "パスワード(Password)" : "Password") : (canDisplayJapanese() ? "変更する場合のみ入力してください。(Input if you want to change password.)" : "Input if you want to change password.")}
          value={password} type={showPassword ? "text" : "password"} // input type を切り替えてパスワードの表示・非表示を切り替える。
          onChange={(e) => setPassword(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position={"end"}>
                <IconButton onClick={toggleShowPassword}>
                  {showPassword ? <FontAwesomeIcon icon={faEye} /> : <FontAwesomeIcon icon={faEyeSlash} />}
                </IconButton>
              </InputAdornment>
            )
          }} />
        <TextField variant="outlined" fullWidth className={classes.input} label={canDisplayJapanese() ? "種別(Role)" : "Role"}  disabled={!create} select value={role} SelectProps={{ native: true }}
          onChange={(e) => {setRole(e.target.value); !["manager", "comanager"].includes(e.target.value) && setEmail("");}} >
          {roles.map(({ value, labelJa, labelEn }) => (
            <option key={value} value={value}>{canDisplayJapanese() ? labelJa : labelEn}</option>
          ))}
        </TextField>
        <TextField required error={validated && !company}
          variant="outlined" fullWidth className={classes.input} label={canDisplayJapanese() ? "会社名(Company Name)" : "Company Name"}  value={company}
          onChange={(e) => setCompany(e.target.value)} />
        <TextField required={["manager", "comanager"].includes(role)} error={validated && (["manager", "comanager"].includes(role) && !email)}
          variant="outlined" fullWidth className={classes.input} label={canDisplayJapanese() ? "メールアドレス(Mail Address)" : "Mail Address"} value={["manager", "comanager"].includes(role) ? email : ""}
          disabled={!["manager", "comanager"].includes(role)}
          onChange={(e) => setEmail(e.target.value)} />
      </DialogContent>

      <DialogActions>
        <Button onClick={close} color="primary">Cancel</Button>
        <Button variant="contained" color="primary" onClick={() => executeRegistry()}>{create ? "Create" : "Update"}</Button>
      </DialogActions>
    </Dialog>
  )
}

type ChangePasswordDialogProps = {
  user: string | null,
  close: () => void
}

/**
 * パスワード変更ダイアログ (管理者用)
 * @param user パスワードを変更する userId. null の場合はパスワード変更ダイアログを表示しない。
 * @param close パスワード変更ダイアログを非表示にする関数
 */
export const ChangePasswordDialog: React.FC<ChangePasswordDialogProps> = ({ user, close }) => {
  const [newPassword, setNewPassword] = React.useState<string>("")
  const [showPassword, setShowPassword] = React.useState<boolean>(false)
  const { success, error } = useNotificationDispatch()

  const toggleShowPassword = () => setShowPassword(!showPassword)

  const closeChangePasswordDialog = () => {
    close()
    setNewPassword("")
  }

  const changePassword = async (userId: string, password: string) => {
    axios.post("/users/changePassword", {
      userId: userId,
      changing: password
    })
      .then(_ => {
        close()
        success(`${userId}のパスワードが変更されました。`, `${userId}'s password has been changed.`)
      })
      .catch(err => {
        error(
          err.response?.data?.detail?.messageJa || `${text.message.unexpected_error.ja} ${err.message}`,
          err.response?.data?.detail?.messageEn || `${text.message.unexpected_error.en} ${err.message}`
        )
      })
    closeChangePasswordDialog()
  }

  return (
    <Dialog open={!!user} onClose={closeChangePasswordDialog} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Change Password</DialogTitle>
      <DialogContent>
        <DialogContentText>Change {user} password.</DialogContentText>
        <TextField // input type を切り替えてパスワードの表示・非表示を切り替える。
          autoFocus margin="dense" label="New Password" type={showPassword ? "text" : "password"} fullWidth
          onChange={(e) => setNewPassword(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position={"end"}>
                <IconButton onClick={toggleShowPassword}>
                  {showPassword ? <FontAwesomeIcon icon={faEye} /> : <FontAwesomeIcon icon={faEyeSlash} />}
                </IconButton>
              </InputAdornment>
            )
          }} />
      </DialogContent>
      <DialogActions>
        <Button onClick={closeChangePasswordDialog} color="primary">Cancel</Button>
        <Button onClick={() => changePassword(user!, newPassword)} color="primary">Change Password</Button>
      </DialogActions>
    </Dialog>
  )
}
