import React from 'react';
import { BrowserRouter as Router, Redirect, Route, RouteProps, Switch } from "react-router-dom";
import { AuthenticationProvider, useAuthenticated, useIsManager, useIsComanager, useIsWorker } from "./AuthenticationContext";
import { LoginPage } from "./components/loginForm";
import { ManagePage } from "./components/managePage";
import { CheckPage } from "./components/checkPage";
import { NotificationProvider } from "./notification";

/**
 * エントリーポイント
 */
const App: React.FC = () => {
  document.title = "作業者管理App"

  return (
    <NotificationProvider>
      <AuthenticationProvider>
        <Router>
          <Switch>
            <UnAuthenticatedRoute exact path={"/login"} component={LoginPage} />
            <AuthenticatedRoute path={"/"} />
          </Switch>
        </Router>
      </AuthenticationProvider>
    </NotificationProvider>
  )
}
export default App

/**
 * 承認されたユーザーの権限に応じて適切な画面のコンポーネントを返却する。権限がない場合はログイン画面のコンポーネントを返却する。
 * @param props
 */
const AuthenticatedRoute: React.FC<RouteProps> = ({...props}) => {
    const isManager = useIsManager()
    const isComanager = useIsComanager()
    const isWorker = useIsWorker()

    if (isManager || isComanager) {
        return <Route {...props} component={ManagePage} />
    } else if (isWorker) {
        return <Route {...props} component={CheckPage} />
    } else {
        return <Redirect to={{pathname: "/login"}} />
    }
}

/**
 * 認証されている場合はトップページにリダイレクトする。認証されていない場合は、そのまま表示する。
 * @param props
 */
const UnAuthenticatedRoute: React.FC<RouteProps> = ({...props}) => {
    const isAuthenticated = useAuthenticated()

    if (isAuthenticated) {
        return <Redirect to={"/"} />
    } else {
        return <Route {...props} />
    }
}
