import React, { useEffect, useState } from "react";
import { useAuthenticatedUser } from "../AuthenticationContext";
import {
  Button,
  Checkbox,
  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid,
  makeStyles,
  Paper,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  TextField,
  Typography
} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import axios from "../http";
import { useNotificationDispatch } from "../notification";
import { formatYYYYMMDD_hhmmss, formatYYYYMMDD } from "../common"
import { FilterMenu } from "./common";
import { canDisplayJapanese, text } from "../localize";

const useStyles_Table = makeStyles({
  table: {
    minWidth: 450,
  },
  container: {
    marginTop: 20,
    maxHeight: 'calc(100vh - 204px)',
    minHeight: 172
  }
})

type Detail = {
  projectId: string,
  projectName: string,
  beginDate: number,
  endDate: number,
  tzOffset: number
}

// プロジェクトの状況によるフィルターの候補
const projectStatus = ["Not Started", "In Progress", "Completed"] as const

/**
 * プロジェクト管理画面
 * @param showApproved 承認画面を表示する関数
 */
export const ManageProject: React.FC<{ showApproved: (project_id: string) => void }> = ({ showApproved }) => {
  const classes = useStyles_Table()
  const [check, setCheck] = useState<string[]>([])
  const [items, setItems] = useState<Detail[]>([])
  const [isLoaded, setIsLoaded] = useState<boolean>(false)

  // プロジェクト名によるフィルターの状態を保持
  const [nameFiltered, setNameFiltered] = useState<string | null>(null)

  // プロジェクトの状況によるフィルターの状態を保持
  const [projectStatusFiltered, setProjectStatusFiltered] = useState<typeof projectStatus[number] | null>(null)

  // プロジェクト編集ダイアログで編集するプロジェクトの projectId. プロジェクト編集ダイアログを表示しない場合は null を格納する。プロジェクトを新規作成する場合は空文字列を格納する。
  const [editProject, setEditProject] = React.useState<string | null>(null)
  const { success, error } = useNotificationDispatch()

  const user = useAuthenticatedUser()

  const refresh = React.useCallback(() => {
    axios.get("/projects/")
      .then(res => {
        setItems(res.data)
        setIsLoaded(true)
      })
      .catch(err => {
        error(
          err.response?.data?.detail?.messageJa || `${text.message.unexpected_error.ja} ${err.message}`,
          err.response?.data?.detail?.messageEn || `${text.message.unexpected_error.en} ${err.message}`
        )
      })
  }, [error])

  const handleCheck = (checked: boolean, value: string) => {
    setCheck(checked ? [...check, value] : check.filter(e => e !== value))
  }

  const openCreateProjectDialog = () => {
    setEditProject("")
  }

  const openUpdateProjectDialog = (project_id: string) => {
    setEditProject(project_id)
  }

  const deleteProjects = async () => {
    axios.post("/projects/delete", {
      projectIds: check,
    })
      .then(_ => {
        success("プロジェクトを削除しました。", "The project has been deleted.")
        refresh()
        setCheck([])
      })
      .catch(err => {
        error(
          err.response?.data?.detail?.messageJa || `${text.message.unexpected_error.ja} ${err.message}`,
          err.response?.data?.detail?.messageEn || `${text.message.unexpected_error.en} ${err.message}`
        )
      })
  }

  const escapeCsvItem = (item: string) => {
    return `"${item.replace(/"/g, '""')}"`
  }

  const downloadCsv = async () => {
    axios.post("/applications/download", {
      projectIds: check,
    })
      .then(res => {
        // サーバーから csv ファイルを直接受け取るのではなく、クライアント側で File API の Blob を経由してダウンロード処理を発火させる。
        const link = document.createElement('a')
        const csv = res.data.map((row: string[]) => row.map(item => escapeCsvItem(item)).join(',')).join('\r\n')  // Json を csv に変換
        const bom = new Uint8Array([0xEF, 0xBB, 0xBF])  // BOM 付きにすることで Excel が UTF-8 として開くことができる。
        const blob = new Blob([bom, csv], { type: 'text/csv' })
        link.href = (window.URL || window.webkitURL).createObjectURL(blob)
        const filename = check.length === 1 ? check[0] : "projects"
        link.download = formatYYYYMMDD_hhmmss(new Date()) + `_${filename}.csv`
        link.click()
        URL.revokeObjectURL(link.href)
        setCheck([])
      })
      .catch(err => {
        error(
          err.response?.data?.detail?.messageJa || `${text.message.unexpected_error.ja} ${err.message}`,
          err.response?.data?.detail?.messageEn || `${text.message.unexpected_error.en} ${err.message}`
        )
      })
  }

  const projectStatusFilter = React.useCallback((e: Detail) => {
    const epochCurrent = (new Date()).getTime() / 1000 - (new Date()).getTimezoneOffset() * 60
    // プロジェクトの開始日と終了日を現在時刻と照らし合わせて、プロジェクトの状況を以下のように判断する。
    switch (projectStatusFiltered) {
      case "Not Started":
        return epochCurrent < e.beginDate
      case "In Progress":
        return e.beginDate < epochCurrent && epochCurrent < e.endDate
      case "Completed":
        return e.endDate < epochCurrent
    }
  }, [projectStatusFiltered])

  useEffect(() => {
    refresh()
  }, [refresh])

  if (!isLoaded) {
    return <div>Loading...</div>
  } else {
    return (
      <div style={{ margin: 20 }}>
        <Typography variant="h5">{canDisplayJapanese() ? "プロジェクト一覧(Projects)" : "Projects"}</Typography>
        <Grid container justify={"space-between"}>
          <Grid item>
            <Button variant="contained" color="primary" disabled={user?.role !== "manager"} style={{ marginTop: 10 }} onClick={openCreateProjectDialog}>Create</Button>
            <Button variant="contained" color="primary" disabled={user?.role !== "manager" || check.length === 0} style={{ marginTop: 10, marginLeft: 10 }} onClick={downloadCsv}>Output CSV</Button>
            <FilterMenu name={"Project Name"} selected={nameFiltered} setSelected={(selected: string | null) => setNameFiltered(selected)} candidates={
              // ユニークなプロジェクト名を抽出
              items.reduce((a: string[], b) => {
                if (!a.includes(b.projectName)) {
                  a.push(b.projectName)
                }
                return a
              }, [])
            } />
            <FilterMenu name={"Status"} selected={projectStatusFiltered} setSelected={(selected) => setProjectStatusFiltered(selected)} candidates={projectStatus} />
          </Grid>
          <Button variant="contained" color="secondary" disabled={check.length === 0} style={{ marginTop: 10, marginLeft: 10 }} onClick={deleteProjects}>Delete</Button>
        </Grid>
        <TableContainer component={Paper} className={classes.container}>
          <Table stickyHeader className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>
                  {canDisplayJapanese() && <div>選択</div>}
                  <div>Select</div>
                </TableCell>
                <TableCell>
                  {canDisplayJapanese() && <div>プロジェクトID</div>}
                  <div>Project ID</div>
                </TableCell>
                <TableCell>
                  {canDisplayJapanese() && <div>プロジェクト名</div>}
                  <div>Project Name</div>
                </TableCell>
                <TableCell>
                  {canDisplayJapanese() && <div>開始日</div>}
                  <div>Start Date</div>
                </TableCell>
                <TableCell>
                  {canDisplayJapanese() && <div>終了日</div>}
                  <div>End Date</div>
                </TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {items
                // プロジェクト名フィルタ
                .filter(e => !nameFiltered || e.projectName === nameFiltered)
                // プロジェクト状況フィルタ
                .filter(e => !projectStatusFiltered || projectStatusFilter(e))
                .map(row => (
                  <TableRow key={row.projectId}>
                    <TableCell><Checkbox disabled={user?.role !== "manager"} checked={check.includes(row.projectId)} onChange={(e) => handleCheck(e.target.checked, e.target.name)} name={row.projectId} /></TableCell>
                    <TableCell><Button color={"primary"} style={{ textTransform: 'none' }} onClick={() => openUpdateProjectDialog(row.projectId)}>{row.projectId}</Button></TableCell>
                    <TableCell>{row.projectName}</TableCell>
                    <TableCell>{formatYYYYMMDD(new Date(row.beginDate * 1000 + (new Date()).getTimezoneOffset() * 60000))}</TableCell>
                    <TableCell>{formatYYYYMMDD(new Date(row.endDate * 1000 + (new Date()).getTimezoneOffset() * 60000))}</TableCell>
                    <TableCell><Button variant="outlined" color="primary" onClick={() => showApproved(row.projectId)}>Approved History</Button></TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        {user?.role === "manager" ? 
          <EditProjectDialog open={editProject} close={() => setEditProject(null)} refresh={refresh} /> 
          : 
          <ShowProjectDialog open={editProject} close={() => setEditProject(null)} /> 
        }
        
      </div>
    )
  }
}

const useStyles_Create = makeStyles({
  input: {
    margin: 8,
  },
})

/**
 * プロジェクト編集ダイアログ
 * @param open 編集するプロジェクトの projectId. プロジェクトを新規作成する場合は空文字列を格納する。
 * @param close プロジェクト編集ダイアログを非表示にする関数
 * @param refresh プロジェクト管理画面を更新する関数
 */
const EditProjectDialog: React.FC<{ open: string | null, close: () => void, refresh: () => void }> = ({ open, close, refresh }) => {
  const classes = useStyles_Create()
  const [projectId, setProjectId] = useState<string>("")
  const [projectName, setProjectName] = useState<string>("")
  const [begin, setBegin] = useState<string>(formatYYYYMMDD(new Date()))
  const [end, setEnd] = useState<string>(formatYYYYMMDD(new Date()))
  const [managers, setManagers] = useState<string[]>([])
  const [comanagers, setComanagers] = useState<string[]>([])
  const [mainComanagers, setMainComanagers] = useState<string[]>([])
  const [sub1Comanagers, setSub1Comanagers] = useState<string[]>([])
  const [sub2Comanagers, setSub2Comanagers] = useState<string[]>([])
  const [workers, setWorkers] = useState<string[]>([])

  // 全管理者を保持する。手入力可能だが、auto completion のために利用する。
  const [candidateManagers, setCandidateManagers] = useState<string[]>([])  
  // 全委託先管理者を保持する。手入力可能だが、auto completion のために利用する。
  const [candidateComanagers, setCandidateComanagers] = useState<string[]>([])
  // 全作業者（運転者と計測者）を保持する。手入力可能だが、auto completion のために利用する。
  const [candidateWorkers, setCandidateWorkers] = useState<string[]>([])

  const [mobilities, setMobilities] = useState<string[]>([])
  const { success, error } = useNotificationDispatch()
  const create = open === ""
  const [validated, setValidated] = React.useState(true)

  useEffect(() => {
    setValidated(false)
    if (!create && !!open) {
      // 編集時は現在の値を取得する。
      axios.get("/projects/get", {
        params: {
          project_id: open
        }
      })
        .then(response => {
          setProjectId(open)
          setProjectName(response.data.projectName)
          setBegin(formatYYYYMMDD(new Date(response.data.beginDate * 1000 + (new Date()).getTimezoneOffset() * 60000)))
          setEnd(formatYYYYMMDD(new Date(response.data.endDate * 1000 + (new Date()).getTimezoneOffset() * 60000)))
          setManagers(response.data.managers)
          setComanagers(response.data.comanagers)
          setMainComanagers(response.data.comanagers[0] ? [response.data.comanagers[0]] : [])
          setSub1Comanagers(response.data.comanagers[1] ? [response.data.comanagers[1]] : [])
          setSub2Comanagers(response.data.comanagers[2] ? [response.data.comanagers[2]] : [])
          setWorkers(response.data.workers)
          setMobilities(response.data.mobilities)
        })
        .catch(err => {
          error(
            err.response?.data?.detail?.messageJa || `${text.message.unexpected_error.ja} ${err.message}`,
            err.response?.data?.detail?.messageEn || `${text.message.unexpected_error.en} ${err.message}`
          )
          close()
          refresh()
        })
    } else {
      // 新規作成時は初期値とする。
      setProjectId("")
      setProjectName("")
      setBegin(formatYYYYMMDD(new Date((new Date()).getTime() + ((new Date()).getTimezoneOffset() * 60000))))
      setEnd(formatYYYYMMDD(new Date((new Date()).getTime() + ((new Date()).getTimezoneOffset() * 60000))))
      setManagers([])
      setComanagers([])
      setMainComanagers([])
      setSub1Comanagers([])
      setSub2Comanagers([])
      setWorkers([])
      setMobilities([])
    }
  }, [create, open, error, close, refresh])

  // 管理者と作業者の候補を取得する。
  useEffect(() => {
    axios.get("/users/managers")
      .then(response => {
        setCandidateManagers(response.data)
      })
      .catch(err => {
        error(
          err.response?.data?.detail?.messageJa || `${text.message.unexpected_error.ja} ${err.message}`,
          err.response?.data?.detail?.messageEn || `${text.message.unexpected_error.en} ${err.message}`
        )
      })
    axios.get("/users/comanagers")
      .then(response => {
        setCandidateComanagers(response.data)
      })
      .catch(err => {
        error(
          err.response?.data?.detail?.messageJa || `${text.message.unexpected_error.ja} ${err.message}`,
          err.response?.data?.detail?.messageEn || `${text.message.unexpected_error.en} ${err.message}`
        )
      })
    axios.get("/users/workers")
      .then(response => {
        setCandidateWorkers(response.data)
      })
      .catch(err => {
        error(
          err.response?.data?.detail?.messageJa || `${text.message.unexpected_error.ja} ${err.message}`,
          err.response?.data?.detail?.messageEn || `${text.message.unexpected_error.en} ${err.message}`
        )
      })
  }, [create, open, error])

  const validateMainComanager = () => {
    return mainComanagers.length === 1
  }

  const validateSub1Comanager = () => {
    return sub1Comanagers.length === 0 || (sub1Comanagers.length === 1 && mainComanagers.length !== 0 && !mainComanagers.includes(sub1Comanagers[0]))
  }

  const validateSub2Comanager = () => {
    return sub2Comanagers.length === 0 || (sub2Comanagers.length === 1 && (mainComanagers.length !== 0 && sub1Comanagers.length !== 0) && ![...mainComanagers, ...sub1Comanagers].includes(sub2Comanagers[0]))
  }

  const isInvalid = () => {
    return (
      !projectId
      || !projectName
      || !begin
      || !end
      || managers.length <= 0
      || !validateMainComanager()
      || !validateSub1Comanager()
      || !validateSub2Comanager()
      || comanagers.length >= 4
      || workers.length <= 0
      || mobilities.length <= 0
    )
  }

  const execute = async () => {
    setValidated(true)

    // 不正な値がないか確認する。
    if (isInvalid()) {
      return
    }
    const endpoint = create ? "/projects/create" : "/projects/update"
    
    axios.post(endpoint, {
      projectId: projectId,
      projectName: projectName,
      beginDate: (new Date(begin + ' 00:00:00')).getTime() / 1000 - (new Date()).getTimezoneOffset() * 60,
      endDate: (new Date(end + ' 23:59:59')).getTime() / 1000 - (new Date()).getTimezoneOffset() * 60,
      managers: managers,
      comanagers: comanagers,
      workers: workers,
      mobilities: mobilities,
    })
      .then(_ => {
        refresh()
        close()
        success(`${projectId} を${create ? "追加" : "更新"}しました。`, `${projectId} has been ${create ? "created" : "updated" }.`)
      })
      .catch(err => {
        error(
          err.response?.data?.detail?.messageJa || `${text.message.unexpected_error.ja} ${err.message}`,
          err.response?.data?.detail?.messageEn || `${text.message.unexpected_error.en} ${err.message}`
        )
      })
  }

  return (
    <Dialog open={create || !!open} onClose={close} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{create ? (canDisplayJapanese() ? "プロジェクト追加(Create Project)" : "Create Project") : (canDisplayJapanese() ? "プロジェクト更新(Update Project)" : "Update Project")}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {create ? (canDisplayJapanese() ? "新しいプロジェクトを追加します。(Create a new project.)" : "Create a new project.")
          : (canDisplayJapanese() ? "プロジェクトを更新します。(Update an existing project.)" : "Update an existing project.")}
        </DialogContentText>
        <TextField required error={validated && !projectId} variant="outlined" fullWidth className={classes.input} label={canDisplayJapanese() ? "プロジェクトID(Project ID)" : "Project ID"}  disabled={!create} value={projectId}
          onChange={(e) => setProjectId(e.target.value)} />
        <TextField required error={validated && !projectName}
          variant="outlined" fullWidth className={classes.input} label={canDisplayJapanese() ? "プロジェクト名(Project Name)" : "Project Name"}  value={projectName}
          onChange={(e) => setProjectName(e.target.value)} />
        <TextField required error={validated && !begin} variant="outlined" fullWidth className={classes.input} label={canDisplayJapanese() ? "開始日(Start Date)" : "Start Date"}  value={begin} type={"date"} InputLabelProps={{ shrink: true }}
          onChange={(e) => setBegin(e.target.value)} />
        <TextField required error={validated && !end} variant="outlined" fullWidth className={classes.input} label={canDisplayJapanese() ? "終了日(End Date)" : "End Date"}  value={end} type={"date"} InputLabelProps={{ shrink: true }}
          onChange={(e) => setEnd(e.target.value)} />
        <Autocomplete
          multiple
          value={managers}
          options={candidateManagers}
          getOptionLabel={(option) => option}
          onChange={(e, values) => setManagers(values)}
          filterSelectedOptions
          renderInput={(params) => (
            <TextField
              {...params}
              required error={validated && managers.length <= 0}
              variant="outlined" fullWidth className={classes.input}
              label={canDisplayJapanese() ? "管理者(Manager)" : "Manager"} 
            />
          )}
        />
        <Autocomplete
          multiple
          value={mainComanagers}
          options={candidateComanagers}
          getOptionLabel={(option) => option}
          onChange={(e, values) => {
            setMainComanagers(values)
            setComanagers([...values, ...sub1Comanagers, ...sub2Comanagers])
          }}
          filterSelectedOptions
          renderInput={(params) => (
            <TextField
              {...params}
              required error={validated && !validateMainComanager()}
              variant="outlined" fullWidth className={classes.input}
              label={canDisplayJapanese() ? "委託先管理者(Comanager)" : "Comanager"} 
            />
          )}
        />
        <Autocomplete
          multiple
          value={sub1Comanagers}
          options={candidateComanagers}
          getOptionLabel={(option) => option}
          onChange={(e, values) => {
            setSub1Comanagers(values)
            setComanagers([...mainComanagers, ...values, ...sub2Comanagers])
          }}
          filterSelectedOptions
          renderInput={(params) => (
            <TextField
              {...params}
              error={validated && !validateSub1Comanager()}
              variant="outlined" fullWidth className={classes.input}
              label={canDisplayJapanese() ? "サブ１委託先管理者(Sub1 Comanager)" : "Sub1 Comanager"} 
            />
          )}
        />
        <Autocomplete
          multiple
          value={sub2Comanagers}
          options={candidateComanagers}
          getOptionLabel={(option) => option}
          onChange={(e, values) => {
            setSub2Comanagers(values)
            setComanagers([...mainComanagers, ...sub1Comanagers, ...values])
          }}
          filterSelectedOptions
          renderInput={(params) => (
            <TextField
              {...params}
              error={validated && !validateSub2Comanager()}
              variant="outlined" fullWidth className={classes.input}
              label={canDisplayJapanese() ? "サブ２委託先管理者(Sub2 Comanager)" : "Sub2 Comanager"} 
            />
          )}
        />        
        <Autocomplete
          multiple
          value={workers}
          options={candidateWorkers}
          getOptionLabel={(option) => option}
          onChange={(e, values) => setWorkers(values)}
          filterSelectedOptions
          renderInput={(params) => (
            <TextField
              {...params}
              required error={validated && workers.length <= 0}
              variant="outlined" fullWidth className={classes.input}
              label={canDisplayJapanese() ? "作業者(Worker)" : "Worker"} 
            />
          )}
        />
        <Autocomplete
          multiple
          value={mobilities}
          options={[]}
          onChange={(e, values) => setMobilities(values.flat())}
          freeSolo // auto completion の選択肢でない値でも手入力が可能となるオプション
          renderInput={(params) => (
            <TextField
              {...params}
              required error={validated && mobilities.length <= 0}
              variant="outlined" fullWidth className={classes.input}
              label={canDisplayJapanese() ? "車両(Vehicle)" : "Vehicle"} 
            />
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={close} color="primary">Cancel</Button>
        <Button variant="contained" color="primary" onClick={() => execute()}>{create ? "Create" : "Update"}</Button>
      </DialogActions>
    </Dialog>
  )
}

/**
 * プロジェクト情報ダイアログ
 * @param open 表示するプロジェクトの projectId.
 * @param close プロジェクト情報ダイアログを非表示にする関数
 * @param refresh プロジェクト管理画面を更新する関数
 */
const ShowProjectDialog: React.FC<{ open: string | null, close: () => void }> = ({ open, close }) => {
  const classes = useStyles_Create()
  const [projectId, setProjectId] = useState<string>("")
  const [projectName, setProjectName] = useState<string>("")
  const [begin, setBegin] = useState<string>(formatYYYYMMDD(new Date()))
  const [end, setEnd] = useState<string>(formatYYYYMMDD(new Date()))
  const [managers, setManagers] = useState<string[]>([])
  const [mainComanagers, setMainComanagers] = useState<string[]>([])
  const [sub1Comanagers, setSub1Comanagers] = useState<string[]>([])
  const [sub2Comanagers, setSub2Comanagers] = useState<string[]>([])
  const [workers, setWorkers] = useState<string[]>([])

  const [mobilities, setMobilities] = useState<string[]>([])
  const { error } = useNotificationDispatch()

  useEffect(() => {
    if (!!open) {
      // 編集時は現在の値を取得する。
      axios.get("/projects/get", {
        params: {
          project_id: open
        }
      })
      .then(response => {
        setProjectId(open)
        setProjectName(response.data.projectName)
        setBegin(formatYYYYMMDD(new Date(response.data.beginDate * 1000 + (new Date()).getTimezoneOffset() * 60000)))
        setEnd(formatYYYYMMDD(new Date(response.data.endDate * 1000 + (new Date()).getTimezoneOffset() * 60000)))
        setManagers(response.data.managers)
        setMainComanagers(response.data.comanagers[0] ? [response.data.comanagers[0]] : [])
        setSub1Comanagers(response.data.comanagers[1] ? [response.data.comanagers[1]] : [])
        setSub2Comanagers(response.data.comanagers[2] ? [response.data.comanagers[2]] : [])
        setWorkers(response.data.workers)
        setMobilities(response.data.mobilities)
      })
      .catch(err => {
        error(
          err.response?.data?.detail?.messageJa || `${text.message.unexpected_error.ja} ${err.message}`,
          err.response?.data?.detail?.messageEn || `${text.message.unexpected_error.en} ${err.message}`
        )
        close()
      })
    }
  }, [open, error, close])

  return (
    <Dialog open={!!open} onClose={close} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">{canDisplayJapanese() ? "プロジェクト情報(Project Information)" : "Project Information"}</DialogTitle>
      <DialogContent>
        <DialogContentText>{canDisplayJapanese() ? "プロジェクトに関する情報を表示します。(Show Information about the Project.)" : "Show Information about the Project."}</DialogContentText>
        <TextField variant="outlined" fullWidth className={classes.input} label={canDisplayJapanese() ? "プロジェクトID(Project ID)" : "Project ID"}  inputProps={{readOnly: true}} value={projectId} />
        <TextField variant="outlined" fullWidth className={classes.input} label={canDisplayJapanese() ? "プロジェクト名(Project Name)" : "Project Name"}  inputProps={{readOnly: true}} value={projectName} />
        <TextField variant="outlined" fullWidth className={classes.input} label={canDisplayJapanese() ? "開始日(Start Date)" : "Start Date"}  inputProps={{readOnly: true}} value={begin} type={"date"} InputLabelProps={{ shrink: true }} />
        <TextField variant="outlined" fullWidth className={classes.input} label={canDisplayJapanese() ? "終了日(End Date)" : "End Date"}  inputProps={{readOnly: true}} value={end} type={"date"} InputLabelProps={{ shrink: true }} />
        <TextField
          value={managers}
          variant="outlined" fullWidth className={classes.input} inputProps={{readOnly: true}}
          label={canDisplayJapanese() ? "管理者(Manager)" : "Manager"} 
        />
        <TextField
          value={mainComanagers}
          variant="outlined" fullWidth className={classes.input} inputProps={{readOnly: true}}
          label={canDisplayJapanese() ? "委託先管理者(Comanager)" : "Comanager"} 
        />
        <TextField
          value={sub1Comanagers}
          variant="outlined" fullWidth className={classes.input} inputProps={{readOnly: true}}
          label={canDisplayJapanese() ? "サブ１委託先管理者(Sub1 Comanager)" : "Sub1 Comanager"} 
        />
        <TextField
          value={sub2Comanagers}
          variant="outlined" fullWidth className={classes.input} inputProps={{readOnly: true}}
          label={canDisplayJapanese() ? "サブ２委託先管理者(Sub2 Comanager)" : "Sub2 Comanager"} 
        />
        <TextField
          value={workers}
          variant="outlined" fullWidth className={classes.input} inputProps={{readOnly: true}}
          label={canDisplayJapanese() ? "作業者(Worker)" : "Worker"} 
        />
        <TextField
          value={mobilities}
          variant="outlined" fullWidth className={classes.input} inputProps={{readOnly: true}}
          label={canDisplayJapanese() ? "車両(Vehicle)" : "Vehicle"} 
        />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={close}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}