import React, {useState} from "react";
import { useAuthenticatedUser, useLogout } from "../AuthenticationContext";
import {
  AppBar,
  Box,
  Button,
  createStyles, Divider,
  Drawer,
  IconButton,
  Link,
  List, ListItem, ListItemText,
  makeStyles,
  Menu, MenuItem,
  Theme,
  Toolbar,
  Typography
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faCaretDown, faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { ChangePasswordDialog, ManageAccount } from "./account";
import { ManageProject } from "./project";
import { AppliedTable, ApprovedTable, Approve } from "./apply";
import { CheckEdit } from "./checkEdit";
import { NotificationProvider, Notification } from "../notification";
import { canDisplayJapanese } from "../localize";

// 管理者用画面に表示する画面
type Page = "申請一覧" | "アカウント管理" | "プロジェクト管理" | "承認済み申請一覧" | "申請承認" | "チェック項目編集"

/**
 * 管理者用画面を管理するコンポーネント
 */
export const ManagePage: React.FC = () => {
  // 現在表示している管理者用画面
  const [selected, setSelected] = React.useState<Page>("申請一覧")

  // 承認済み申請一覧に表示するプロジェクトの projectId を保持する。
  const [project, setProject] = React.useState<string>("")

  // 申請承認画面に表示する申請の applicationId を保持する。
  const [application, setApplication] = React.useState<string>("")

  // 戻るボタン押下時に遷移する画面を保持する。
  const [backTo, setBackTo] = React.useState<Page>("申請一覧")

  // 承認済み申請一覧のフィルターの状態を保持する。（戻るを押下しても状態を維持するため。）
  const [userFiltered, setUserFiltered] = useState<string | null>(null)
  const [dateFiltered, setDateFiltered] = useState<string>("")

  // 申請承認画面を表示する関数
  const showApprove = (backTo: Page) => {
    return (applicationId: string) => {
      setSelected("申請承認")
      setApplication(applicationId)
      setBackTo(backTo)
    }
  }

  // 承認済み申請一覧画面を表示する関数
  const showApprovedTable = (projectId: string) => {
    setSelected("承認済み申請一覧")
    setUserFiltered(null)
    setDateFiltered("")
    setProject(projectId)
  }

  // プロジェクト管理画面を表示する関数
  const showManageProject = () => {
    setSelected("プロジェクト管理")
  }

  // 選択されている画面に応じて適切なコンポーネントに振り分ける。
  const selectedPage = (selected: Page) => {
    switch (selected) {
      case "申請一覧":
        return <AppliedTable showApprove={showApprove("申請一覧")} />
      case "アカウント管理":
        return <ManageAccount />
      case "プロジェクト管理":
        return <ManageProject showApproved={showApprovedTable} />
      case "チェック項目編集":
        return <CheckEdit />
      case "承認済み申請一覧":
        return <ApprovedTable
          showApprove={showApprove("承認済み申請一覧")}
          project_id={project}
          showManageProject={showManageProject}
          userFiltered={userFiltered}
          setUserFiltered={setUserFiltered}
          dateFiltered={dateFiltered}
          setDateFiltered={setDateFiltered}
        />
      case "申請承認":
        return <Approve applicationId={application} back={() => setSelected(backTo)} />
    }
  }

  return (
    <NotificationProvider>
      <Navigation selected={selected} setSelected={setSelected}>
        {selectedPage(selected)}
      </Navigation>
    </NotificationProvider>
  )
}

const drawerWidth = 200
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    toolbar: {
      paddingLeft: 0,
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      height: "calc(100vh - 64px)",
      marginTop: 64,
      width: drawerWidth,
    },
    title: {
      flexGrow: 1,
    },
    content: {
      paddingTop: 64,
      paddingLeft: drawerWidth,
    },
    contentShift: {
      paddingTop: 64,
    },
    menu: {
      marginTop: 45,
    },
  })
)

type NavigationProps = {
  selected: string,
  setSelected: (selected: Page) => void
}

/**
 * 管理者用画面の上部のバーと左側のメニュー
 * @param selected メイン部分に表示する画面を示す
 * @param setSelected メイン部分に表示する画面を変更する関数
 * @param children メイン部分に表示するコンポーネント
 */
export const Navigation: React.FC<NavigationProps> = ({ selected, setSelected, children }) => {
  const classes = useStyles()

  // 左側のメニューの折り畳み状態を保持する。
  const [open, setOpen] = React.useState(true)

  // ログインしているユーザーのパスワードを変更する画面を表示する。null の場合、画面を表示しない。
  const [changePasswordUser, setChangePasswordUser] = React.useState<string | null>(null)

  const logout = useLogout()
  const user = useAuthenticatedUser()

  return (
    <div className={classes.root}>
      <AppBar position="fixed">
        <Toolbar classes={{ root: classes.toolbar }}>
          <IconButton color="inherit" onClick={() => setOpen(!open)}><FontAwesomeIcon icon={faBars} /></IconButton>
          <Typography className={classes.title}>{user?.userId}[{user?.role}]</Typography>
          <MenuButton />
          <Button color="inherit" onClick={() => setChangePasswordUser(user?.userId ?? null)}>Change Password</Button>
          <Button color="inherit" onClick={() => logout()}>Logout</Button>
        </Toolbar>
      </AppBar>
      <Drawer variant="persistent" anchor="left" open={open} className={classes.drawer} classes={{ paper: classes.drawerPaper }}>
        <Divider />
        <List>
          {([{ title: "申請一覧", title_en: "Applications" }] as const).map((item, _) => (
            canDisplayJapanese() ?
            <ListItem button key={item.title} selected={selected === item.title} onClick={() => setSelected(item.title)}>
              <ListItemText primary={item.title} secondary={item.title_en}/>
            </ListItem>
            :
            <ListItem button key={item.title} selected={selected === item.title} onClick={() => setSelected(item.title)}>
              <ListItemText primary={item.title_en}/>
            </ListItem>
          ))}
        </List>
        <Divider />
        {user?.role === "manager" ? 
          <List>
          {([
            { title: "アカウント管理", title_en: "Accounts" },
            { title: "プロジェクト管理", title_en: "Projects" },
            { title: "チェック項目編集", title_en: "Check Items" }] as const).map((item, _) => (
              canDisplayJapanese() ?
              <ListItem button key={item.title} selected={selected === item.title} onClick={() => setSelected(item.title)}>
                <ListItemText primary={item.title} secondary={item.title_en}/>
              </ListItem>
              :
              <ListItem button key={item.title} selected={selected === item.title} onClick={() => setSelected(item.title)}>
                <ListItemText primary={item.title_en}/>
              </ListItem>
          ))}
          </List>
        :
          <List>
          {([ { title: "プロジェクト管理", title_en: "Projects" }] as const).map((item, _) => (
            canDisplayJapanese() ?
            <ListItem button key={item.title} selected={selected === item.title} onClick={() => setSelected(item.title)}>
              <ListItemText primary={item.title} secondary={item.title_en}/>
            </ListItem>
            :
            <ListItem button key={item.title} selected={selected === item.title} onClick={() => setSelected(item.title)}>
              <ListItemText primary={item.title_en}/>
            </ListItem>            
          ))}
          </List>
        }
      </Drawer>
      <main className={open ? classes.content : classes.contentShift}>
        {children}
        <Notification />
      </main>
      <ChangePasswordDialog user={changePasswordUser} close={() => setChangePasswordUser(null)} />
    </div>
  )
}

const MenuButton: React.FC = () => {
  const classes = useStyles()

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <Button color="inherit" id="basic-button" onClick={handleClick}>
        Manual<FontAwesomeIcon icon={faCaretDown} style={{ marginLeft: "0.5em" }}/>
      </Button>
      <Menu className={classes.menu} id="basic-menu" anchorEl={anchorEl} anchorOrigin={{ vertical: "bottom", horizontal: "right" }} transformOrigin={{ vertical: 'top', horizontal: 'right' }} open={open} onClose={handleClose}>
        <MenuItem onClick={handleClose}>
          <Link underline="none" href={`${process.env.PUBLIC_URL}/manual/manual-ja.html`} target="_blank">
            日本語<FontAwesomeIcon icon={faExternalLinkAlt} style={{ marginLeft: "0.5em" }}/>
          </Link>
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <Link underline="none" href={`${process.env.PUBLIC_URL}/manual/manual-en.html`} target="_blank" >
            English<FontAwesomeIcon icon={faExternalLinkAlt} style={{ marginLeft: "0.5em" }}/>
          </Link>
        </MenuItem>
      </Menu>
    </Box>
  )
}