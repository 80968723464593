import React, { useEffect, useState } from "react";
import {
  Button, Checkbox,
  createStyles,
  Grid,
  InputAdornment,
  makeStyles,
  Paper,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  TextField,
  Tooltip,
  Typography
} from "@material-ui/core";
import PersonIcon from '@material-ui/icons/Person';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import axios from "../http";
import { formatISO8601 } from "../common";
import { useNotificationDispatch } from "../notification";
import { QuestionGroupType } from "./checkPage";
import { FilterMenu } from "./common";
import { useAuthenticatedUser } from "../AuthenticationContext";
import { canDisplayJapanese, text } from "../localize";

// 下位の管理者がすべて承認していれば true を返す。approvals は下位の管理者から並んでいることが前提。
const isAllSubordinateApproved = (userId: string, approvals: Approval[]) => {
  let approved = true
  for (const approval of approvals) {
    if (approval.approvedUser === userId) break
    approved &&= approval.approved
  }
  return approved
}
// 上位の管理者が一人でも承認していれば true を返す。approvals は下位の管理者から並んでいることが前提。
const isSomeSuperiorApproved = (userId: string, approvals: Approval[]) => {
  let approved = false
  for (const approval of [...approvals].reverse()) {
    if (approval.approvedUser === userId) break
    approved ||= approval.approved
  }
  return approved
}

// 引数に渡したユーザーが承認していれば true を返す。
const isUserApproved = (userId: string | undefined, approvals: Approval[]) => {
  return !!userId && approvals.some(approval => (
    approval.approvedUser === userId && approval.approved
  ))
}

const useStyles_Table = makeStyles({
  table: {
    minWidth: 450,
  },
  container: {
    marginTop: 20
  }
})

type Item = {
  applicationId: string,
  projectId: string,
  projectName: string,
  appliedDate: number,
  appliedUser: string,
  beforeDriving: boolean,
  appliedTzOffset: number,
  approvals: Approval[],
  mobilityNumber: string
}

type Approval = {
  approved: boolean,
  approvedDate: number,
  approvedTzOffset: number,
  approvedUser: string,
}

// 承認可否によるフィルターの候補
const approveStatus = ["Can Approve"] as const

/**
 * 未承認申請一覧画面
 * @param showApprove 承認画面を表示する関数
 */
export const AppliedTable: React.FC<{ showApprove: (applicationId: string) => void }> = ({ showApprove }) => {
  const [items, setItems] = useState<Item[]>([])
  const [isLoaded, setIsLoaded] = useState<boolean>(false)
  const { error } = useNotificationDispatch()

  // 承認可否によるフィルターの状態を保持
  const [approveFiltered, setApproveFiltered] = useState<typeof approveStatus[number] | null>(null)
  
  const user = useAuthenticatedUser()

  const refresh = React.useCallback(() => {
    axios.get("applications/applied")
      .then(response => {
        setItems(response.data)
        setIsLoaded(true)
      })
      .catch(err => {
        error(
          err.response?.data?.detail?.messageJa || `${text.message.unexpected_error.ja} ${err.message}`,
          err.response?.data?.detail?.messageEn || `${text.message.unexpected_error.en} ${err.message}`
        )
      })
  }, [error])

  const approveSatusFilter = React.useCallback((userId: string | undefined, e: Item) => {
    switch (approveFiltered) {
      case "Can Approve":
        return !!userId && isAllSubordinateApproved(userId, e.approvals) && !isSomeSuperiorApproved(userId, e.approvals)
    }
  }, [approveFiltered])

  useEffect(() => {
    refresh()
  }, [refresh])

  if (!isLoaded) {
    return <div>Loading...</div>
  } else {
    return (
      <div>
        <div style={{ margin: 20 }}>
          <Typography variant="h5">{canDisplayJapanese() ? "未承認申請一覧(Unapproved Applications)" : "Unapproved Applications"}</Typography>
          <Grid container justify={"space-between"}>
            <Grid item>
              <FilterMenu name={"Status"} selected={approveFiltered} setSelected={(selected) => setApproveFiltered(selected)} candidates={approveStatus} />
            </Grid>
          </Grid>
          <ApplicationTable
            items={items
              .filter(item => !isUserApproved(user?.userId, item.approvals))
              .filter(item => !approveFiltered || approveSatusFilter(user?.userId, item))}
            showApprove={showApprove}/>
        </div>
        <div hidden={user?.role === "manager"} style={{margin: 20}}>
          <Typography variant="h5">{canDisplayJapanese() ? "承認済み申請一覧(Approved Applications)" : "Approved Applications"}</Typography>
          <ApplicationTable
            items={items.filter(item => isUserApproved(user?.userId, item.approvals))}
            showApprove={showApprove}/>
        </div>
      </div>
    )
  }
}

/**
 * 承認状況を表示するテーブル
 * @param items 表示する申請の配列
 * @param showApprove 承認画面を表示する関数 
 * @returns 
 */
const ApplicationTable: React.FC<{ items: Item[], showApprove: (applicationId: string) => void }> = ({ items, showApprove }) => {
  const classes = useStyles_Table()
  const user = useAuthenticatedUser()

  return (
    <TableContainer component={Paper} className={classes.container}>
    <Table className={classes.table}>
      <TableHead>
        <TableRow>
          <TableCell>
            {canDisplayJapanese() && <div>プロジェクトID</div>}
            <div>Project ID</div>
          </TableCell>               
          <TableCell>
            {canDisplayJapanese() && <div>プロジェクト名</div>}
            <div>Project Name</div>
          </TableCell>
          <TableCell>
            {canDisplayJapanese() && <div>申請内容</div>}
            <div>Application Type</div>
          </TableCell>
          <TableCell>
            {canDisplayJapanese() && <div>申請日時</div>}
            <div>Application Date</div>
          </TableCell>
          <TableCell>
            {canDisplayJapanese() && <div>申請者</div>}
            <div>Applicant</div>
          </TableCell>
          <TableCell>
            {canDisplayJapanese() && <div>申請状況</div>}
            <div>Status</div>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {items.map(row => (
          <TableRow hover key={row.applicationId} onClick={(_) => showApprove(row.applicationId)} style={{ cursor: 'pointer' }}>
            <TableCell>{row.projectId}</TableCell>
            <TableCell>{row.projectName}</TableCell>
            <TableCell>
              {row.beforeDriving ? (canDisplayJapanese() ? "走行前チェック(Before driving check)" : "Before driving check") 
              : (canDisplayJapanese() ? "走行後チェック(After driving check)" : "After driving check")}
            </TableCell>
            <TableCell>{
              // サーバーからはUTCエポック[秒]とオフセット[分]を受信する
              // UTCエポック秒からjsのDate型に変換し、それを画面表示用にフォーマットする。
              // ※ただし、js のDate型は、UTCエポック秒を渡してもローカルタイムとしてインスタンス化するため、ローカルのTZ offsetをオフセットしてnew Dateのコンストラクタに渡す
              formatISO8601(new Date(row.appliedDate * 1000 - (row.appliedTzOffset * 60000) + ((new Date().getTimezoneOffset() * 60000))), row.appliedTzOffset)}</TableCell>
            <TableCell>{row.appliedUser}</TableCell>
            <TableCell><ApprovalStatus appliedUser={row.appliedUser} loginUser={user?.userId} approvals={row.approvals}></ApprovalStatus></TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
  )
}

const useStyles_Approve = makeStyles(() => (
  createStyles({
    paper: {
      marginTop: 15,
    },
    category: {
      padding: 5,
    },
    button: {
      marginTop: 10,
      marginRight: 5,
    },
  })
))

/**
 * 承認画面
 * @param applicationId 承認画面に表示する申請の applicationId
 * @param back 戻る押下時の処理
 */
export const Approve: React.FC<{ applicationId: string, back: () => void }> = ({ applicationId, back }) => {
  const classes = useStyles_Approve()
  const [form, setForm] = React.useState<QuestionGroupType[]>([])
  const [beforeDriving, setBeforeDriving] = React.useState(false)
  const [mobilityNumber, setMobilityNumber] = React.useState("")
  const [appliedDate, setAppliedDate] = React.useState("")
  const [appliedUser, setAppliedUser] = React.useState("")
  const [canApprove, setCanApprove] = React.useState(true)
  const [canDelete, setCanDelete] = React.useState(true)
  const [projectName, setProjectName] = React.useState("")

  // 申請者の回答を順に flat な配列に保持する。申請フォームのカテゴリなどの区分はないことに留意する。
  const [answers, setAnswers] = React.useState([])

  // 承認者による訂正を順に flat な配列に保持する。answers と同じ長さの配列であることを期待する。
  const [corrections, setCorrections] = React.useState<string[]>([])
  // 訂正が行われたかどうかを判定するため、訂正前の値を保持する。
  const [originalCorrections, setOriginalCorrections] = React.useState<string[]>([])

  // 訂正者を順に flat な配列に保持する。answers と同じ長さの配列であることを期待する。
  const [correctors, setCorrectors] = React.useState<string[]>([])

  const { success, error } = useNotificationDispatch()
  const user = useAuthenticatedUser()


  useEffect(() => {
    axios.get(`applications/application/${applicationId}`)
      .then(response => {
        setAnswers(response.data.answers)
        setForm(response.data.form.groups)
        setCorrections(response.data.corrections)
        setOriginalCorrections(response.data.corrections)
        setCorrectors(response.data.correctors)
        setMobilityNumber(response.data.info.mobilityNumber)
        setAppliedDate(formatISO8601(new Date(response.data.info.appliedDate * 1000 - (response.data.info.appliedTzOffset * 60000) + ((new Date().getTimezoneOffset() * 60000))), response.data.info.appliedTzOffset))
        setAppliedUser(response.data.info.appliedUser)
        setBeforeDriving(response.data.info.beforeDriving)
        setProjectName(response.data.info.projectName)
        // 申請を承認可能なのは、自身より下位の管理者がすべて承認しており、かつ、自身より上位の管理者がだれも承認していない場合のみ
        setCanApprove(
          !!user 
          && ["manager", "comanager"].includes(user.role) 
          && isAllSubordinateApproved(user.userId, response.data.info.approvals) 
          && !isSomeSuperiorApproved(user.userId, response.data.info.approvals)
        )
        // 申請を削除可能なのは、自身より上位の管理者がだれも承認していない場合のみ
        setCanDelete(
          !!user
          && ["manager", "comanager"].includes(user.role)
          && !isSomeSuperiorApproved(user.userId, response.data.info.approvals)
        )
      })
      .catch(err => {
        error(
          err.response?.data?.detail?.messageJa || `${text.message.unexpected_error.ja} ${err.message}`,
          err.response?.data?.detail?.messageEn || `${text.message.unexpected_error.en} ${err.message}`
        )
      })
  }, [applicationId, error, user])

  const executeApprove = async () => {
    // answers と配列の長さが等しくなるように、undefined を 空文字列（""）に置き換える。
    let data: string[] = []
    for (let i = 0; i < answers.length; i++) {
      data[i] = corrections[i] ? (corrections[i] !== originalCorrections[i] ? corrections[i] : "") : ""
    }

    axios.post("applications/approve", {
      applicationId: applicationId,
      corrections: data,
      tzOffset: (new Date()).getTimezoneOffset()
    })
      .then(_ => {
        success("承認しました。", "The application has been approved.")
        back()
      })
      .catch(err => {
        error(
          err.response?.data?.detail?.messageJa || `${text.message.unexpected_error.ja} ${err.message}`,
          err.response?.data?.detail?.messageEn || `${text.message.unexpected_error.en} ${err.message}`
        )
      })
  }

  const handleBack = () => {
    back()
  }

  const deleteApplications = async () => {
    axios.post("/applications/delete", {
      applicationIds: [applicationId],
    })
      .then(_ => {
        success("申請を削除しました。", "The application has been deleted.")
        back()
      })
      .catch(err => {
        error(
          err.response?.data?.detail?.messageJa || `${text.message.unexpected_error.ja} ${err.message}`,
          err.response?.data?.detail?.messageEn || `${text.message.unexpected_error.en} ${err.message}`
        )
      })
  }

  // i 番目の回答を訂正すると corrections 配列の i 番目に値（回答）が格納される。したがって、訂正されない項目は undefined になっている。
  const updateCorrection = (correction: string, index: number) => {
    const newArray = [...corrections]
    newArray[index] = correction
    setCorrections(newArray)
  }

  // 申請フォームの質問ひとつずつにユニークな通し番号(カテゴリなどの区分を横断する)として利用する。
  let question_index = 0

  return (
    <div style={{ margin: 20 }}>
      <Typography variant="h5">
        {canDisplayJapanese() ? "申請内容(Application)" : "Application"}
      </Typography>
      <Typography variant="h6" style={{ fontSize: "1.4rem", marginTop: 10 }}>
        {beforeDriving ? (canDisplayJapanese() ? "走行前チェック(Before driving check)" : "Before driving check") 
        : (canDisplayJapanese() ? "走行後チェック(After driving check)" : "After driving check")}
      </Typography>

      <Grid container>
        <Grid item>
          <Typography style={{ marginRight: 15 }}>{canDisplayJapanese() ? "プロジェクト名(Project Name)" : "Project Name"}: {projectName}</Typography>
        </Grid>
        <Grid item>
          <Typography style={{ marginRight: 15 }}>{canDisplayJapanese() ? "申請者(Applicant)" : "Applicant"}: {appliedUser}</Typography>
        </Grid>
        <Grid item>
          <Typography style={{ marginRight: 15 }}>{canDisplayJapanese() ? "車両番号(Vehicle Number)" : "Vehicle Number"}: {mobilityNumber}</Typography>
        </Grid>
        <Grid item>
          <Typography style={{ marginRight: 15 }}>{canDisplayJapanese() ? "申請日時(Applied Date)" : "Applied Date"}: {appliedDate}</Typography>
        </Grid>
      </Grid>

      {form.map((value, category_index) => (
        <Paper key={category_index} className={classes.paper}>
          <div className={classes.category}>
            {canDisplayJapanese() && <Typography variant="h6">{value.category}</Typography>}
            <Typography variant="h6">{value.category_en}</Typography>
          </div>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    {canDisplayJapanese() && <div>確認内容</div>}
                    <div>Question</div>
                  </TableCell>
                  <TableCell>
                    {canDisplayJapanese() && <div>回答</div>}
                    <div>Answer</div>
                  </TableCell>
                  <TableCell>
                    {canDisplayJapanese() && <div>訂正欄</div>}
                    <div>Correction</div>
                  </TableCell>
                  <TableCell>
                    {canDisplayJapanese() && <div>最終訂正者</div>}
                    <div>Corrected By</div>
                  </TableCell>                  
                </TableRow>
              </TableHead>
              <TableBody>
                {value.questions.map((question) => {
                  // 質問を数え上げることで、corrections 配列の何番目を更新すればよいか分かる。
                  const index = question_index++
                  return (
                    <CorrectAnswer 
                    key={`${category_index}_${question_index}`}
                    sentence={question.sentence} 
                    sentence_en={question.sentence_en}
                    candidates={question.candidates} unit={question.unit}
                    answer={answers[index]}
                    correction={corrections[index]}
                    corrector={correctors[index]}
                    canCorrect={canApprove}
                    updateCorrection={(correction: string) => updateCorrection(correction, index)} />
                    )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      ))}
      <div>
        <Grid container direction="row" justify="space-between">
          <Grid>
            <Button className={classes.button} variant="contained" color="primary" onClick={() => executeApprove()} disabled={!canApprove} >Approve</Button>
            <Button className={classes.button} variant="contained" color="primary" onClick={handleBack}>Back</Button>
          </Grid>
          <Grid>
            <Button className={classes.button} variant="contained" color="secondary" onClick={() => deleteApplications()} disabled={!canDelete} >Delete</Button>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

type ApprovalStatusProps = {
  appliedUser: string,
  loginUser: string | undefined,
  approvals: Approval[]
}

/**
 * 申請の承認状況を視覚的に表示する要素
 * @param appliedUser 申請者
 * @param loginUser ログインユーザー
 * @param approvals 承認状況
 */
const ApprovalStatus: React.FC<ApprovalStatusProps> = ({ appliedUser, loginUser, approvals }) => {
  return (
    <div>
      <Tooltip title={appliedUser} placement="top" arrow><PersonIcon color="disabled" /></Tooltip>
      {approvals.map(approval => (
        <Tooltip title={approval.approvedUser} placement="top" arrow>
          {approval.approved ? 
            <PersonIcon color={approval.approvedUser === loginUser ? "primary" : "disabled"}/>
            :
            <PersonOutlineIcon
              color={!!loginUser && approval.approvedUser === loginUser ? 
                (isAllSubordinateApproved(loginUser, approvals) ? "secondary" : "primary") 
                :
                "disabled"} />
          }
        </Tooltip>
      ))}
    </div>
  )
}

type CorrectAnswerProps = {
  sentence: string
  sentence_en: string
  candidates: string[]
  unit: string
  answer: string
  correction: string
  corrector: string
  canCorrect: boolean
  updateCorrection: (correction: string) => void
}

/**
 * 承認画面の行要素（質問・回答・訂正をひとつのコンポーネントとして扱う）
 * @param sentence 質問（日本語）
 * @param sentence_en 質問（英語）
 * @param candidates 回答候補（訂正候補として利用する）
 * @param unit 単位（使用しない場合は空文字列を与える）
 * @param answer 回答
 * @param correction 訂正
 * @param corrector 訂正者
 * @param canCorrect 訂正可否
 * @param updateCorrection 訂正時のコールバック関数
 */
const CorrectAnswer: React.FC<CorrectAnswerProps> = ({ sentence, sentence_en, candidates, unit, answer, correction, corrector, canCorrect, updateCorrection }) => {
  const handleOnChange = (value: string) => {
    updateCorrection(value)
  }

  return (
    <TableRow>
      <TableCell style={{ width: "100%" }}>
        {canDisplayJapanese() && <div>{sentence}</div>}
        <div>{sentence_en}</div>
      </TableCell>
      <TableCell style={{ minWidth: 160 }}>{answer}{unit && `[${unit}]`}</TableCell>
      <TableCell style={{ minWidth: 160 }}>
        {candidates.length > 0
          ? <TextField
            value={correction} variant="outlined" select fullWidth SelectProps={{ native: true }} disabled={!canCorrect}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleOnChange(e.target.value)}>
            <option key={"none"} value={""} /> {/* 訂正しないことを示す空文字列 */}
            {candidates.map((candidate, index) => (
              <option key={index} value={candidate}>{candidate}</option>
            ))}
          </TextField>
          // 回答候補が存在しない場合は自由記入欄として扱う
          : (unit === "" ?
            // 単位がない場合は複数行入力可とする
            <TextField
            multiline rows={3}
            value={correction} variant={"outlined"} fullWidth disabled={!canCorrect}
            onChange={(e) => handleOnChange(e.target.value)} />
            :
            <TextField
            InputProps={{ endAdornment: <InputAdornment position="end">{unit}</InputAdornment>}}
            value={correction} variant={"outlined"} fullWidth disabled={!canCorrect}
            onChange={(e) => handleOnChange(e.target.value)} />
          )
        }
      </TableCell>
      <TableCell style={{ minWidth: 100 }}>{corrector}</TableCell>
    </TableRow>
  )
}

type ApprovedTableProps = {
  showApprove: (applicationId: string) => void,
  project_id: string,
  showManageProject: () => void
  userFiltered: string | null,
  setUserFiltered: (value: string | null) => void
  dateFiltered: string
  setDateFiltered: (value: string) => void
}

/**
 * 承認済み一覧画面
 * @param showApprove 承認画面を表示する関数
 * @param project_id プロジェクトID
 * @param showManageProject プロジェクト管理画面を表示する関数
 * @param userFiltered フィルターする申請者の userId. フィルターしない場合は null を格納する。
 * @param setUserFiltered 申請者フィルターの状態を更新する関数
 * @param dateFiltered フィルターする申請日の date. フィルターしない場合は空文字列を格納する。
 * @param setDateFiltered 申請日フィルターの状態を更新する関数
 */
export const ApprovedTable: React.FC<ApprovedTableProps> = ({ showApprove, project_id, showManageProject, userFiltered, setUserFiltered, dateFiltered, setDateFiltered }) => {
  const classes = useStyles_Table()
  const [items, setItems] = useState<Item[]>([])
  const [check, setCheck] = useState<string[]>([])
  const [isLoaded, setIsLoaded] = useState<boolean>(false)
  const { success, error } = useNotificationDispatch()

  const user = useAuthenticatedUser()

  const handleCheck = (checked: boolean, name: string) => {
    setCheck(checked ? [...check, name] : check.filter(e => e !== name))
  }

  const dateFilter = React.useCallback((e: Item) => {
    const epochFiltered = (new Date(dateFiltered)).getTime() / 1000
    return epochFiltered <= e.appliedDate - e.appliedTzOffset * 60 && e.appliedDate - e.appliedTzOffset * 60 < epochFiltered + 24 * 60 * 60
  }, [dateFiltered])

  const deleteApplications = async () => {
    axios.post("/applications/delete", {
      applicationIds: check,
    })
      .then(_ => {
        success("承認済みの申請を削除しました。", "The approved application has been deleted.")
        refresh()
        setCheck([])
      })
      .catch(err => {
        error(
          err.response?.data?.detail?.messageJa || `${text.message.unexpected_error.ja} ${err.message}`,
          err.response?.data?.detail?.messageEn || `${text.message.unexpected_error.en} ${err.message}`
        )
      })
  }

  const refresh = React.useCallback(() => {
    axios.get("applications/approved", {
      params: {
        project_id: project_id
      }
    })
      .then(response => {
        setItems(response.data)
        setIsLoaded(true)
      })
      .catch(err => {
        error(
          err.response?.data?.detail?.messageJa || `${text.message.unexpected_error.ja} ${err.message}`,
          err.response?.data?.detail?.messageEn || `${text.message.unexpected_error.en} ${err.message}`
        )
      })
  }, [project_id, error])

  useEffect(() => {
    refresh()
  }, [refresh])

  if (!isLoaded) {
    return <div>Loading...</div>
  } else {
    return (
      <div style={{ margin: 20 }}>
        <Typography variant="h5">{canDisplayJapanese() ? "承認済み申請一覧(Approved Applications)" : "Approved Applications"}</Typography>
        <Grid container justify={"space-between"}>
          <Grid item>
            <Button variant={"contained"} color={"primary"} style={{ marginTop: 10 }} onClick={showManageProject}>Back</Button>
            <FilterMenu name={"Applicant ID"} selected={userFiltered} setSelected={(selected: string | null) => setUserFiltered(selected)} candidates={
              // ユニークな申請者を抽出する。
              items.reduce((a: string[], b) => {
                if (!a.includes(b.appliedUser)) {
                  a.push(b.appliedUser)
                }
                return a
              }, [])
            } />
            <TextField variant="outlined" style={{ marginLeft: 10 }} label="Applicated Date" InputLabelProps={{ shrink: true }}
              value={dateFiltered} type={"date"} /* 日付を入力するフォームはブラウザの実装に依存する */
              onChange={(e) => setDateFiltered(e.target.value)} />
            <Button variant={"contained"} color={"secondary"} style={{ marginTop: 10, marginLeft: 10 }} disabled={!dateFiltered} onClick={() => setDateFiltered("")}>Clear Filter By Date</Button>
          </Grid>
          <Button variant="contained" color="secondary" disabled={user?.role !== "manager" || check.length === 0} style={{ marginTop: 10, marginLeft: 10 }} onClick={deleteApplications}>Delete</Button>
        </Grid>
        <TableContainer component={Paper} className={classes.container}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell>
                  {canDisplayJapanese() && <div>選択</div>}
                  <div>Select</div>
                </TableCell>
                <TableCell>
                  {canDisplayJapanese() && <div>申請内容</div>}
                  <div>Application Type</div>
                </TableCell>
                <TableCell>
                  {canDisplayJapanese() && <div>申請日時</div>}
                  <div>Applied Date</div>
                </TableCell>
                <TableCell>
                  {canDisplayJapanese() && <div>申請者</div>}
                  <div>Applicant</div>
                </TableCell>
                <TableCell>
                  {canDisplayJapanese() && <div>承認日時</div>}
                 <div>Approved Date</div>
                </TableCell>
                <TableCell>
                  {canDisplayJapanese() && <div>承認者</div>}
                  <div>Approver</div>
                </TableCell>
                <TableCell>
                  {canDisplayJapanese() && <div>車両番号</div>}
                  <div>Vehicle Number</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {items
                // 申請者フィルター
                .filter(e => !userFiltered || e.appliedUser === userFiltered)
                // 申請日フィルター
                .filter(e => !dateFiltered || dateFilter(e))
                .map(row => (
                  <TableRow hover key={row.applicationId} onClick={() => showApprove(row.applicationId)} style={{ cursor: 'pointer' }}>
                    <TableCell><Checkbox disabled={user?.role !== "manager"} checked={check.includes(row.applicationId)} onClick={(e) => e.stopPropagation()} onChange={(e) => handleCheck(e.target.checked, e.target.name)} name={row.applicationId} /></TableCell>
                    <TableCell>
                      {row.beforeDriving ? (canDisplayJapanese() ? "走行前チェック(Before driving check)" : "Before driving check") 
                      : (canDisplayJapanese() ? "走行後チェック(After driving check)" : "After driving check")}
                    </TableCell>
                    <TableCell>{formatISO8601(new Date(row.appliedDate * 1000 - (row.appliedTzOffset * 60000) + ((new Date().getTimezoneOffset() * 60000))), row.appliedTzOffset)}</TableCell>
                    <TableCell>{row.appliedUser}</TableCell>
                    <TableCell>{formatISO8601(new Date(row.approvals.slice(-1)[0].approvedDate * 1000 - (row.approvals.slice(-1)[0].approvedTzOffset * 60000) + ((new Date().getTimezoneOffset() * 60000))), row.approvals.slice(-1)[0].approvedTzOffset)}</TableCell>
                    <TableCell>{row.approvals.slice(-1)[0].approvedUser}</TableCell>
                    <TableCell>{row.mobilityNumber}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    )
  }
}
