import {
  Button, Container, IconButton, InputAdornment,
  makeStyles, Paper, TextField,
  Theme, Typography,
} from "@material-ui/core";
import React, {useState} from "react";
import {useHistory} from "react-router-dom";
import {useLogin} from "../AuthenticationContext";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faEyeSlash} from "@fortawesome/free-solid-svg-icons";
import { Notification } from "../notification"

/**
 * ログイン画面
 */
export const LoginPage: React.FC = () => {
  const classes = makeStyles((theme: Theme) => ({
    container: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      paddingTop: theme.spacing(4)
    },
    form: {
      padding: "20px 35px 20px 20px",
    },
    input: {
      margin: 8,
    },
  }))()

  const history = useHistory()
  const login = useLogin()

  const [userId, setUserId] = useState<string>("")
  const [password, setPassword] = useState<string>("")
  const [showPassword, setShowPassword] = React.useState<boolean>(false)

  const toggleShowPassword = () => setShowPassword(!showPassword)

  const executeLogin = async () => {
    if (await login(userId, password)) {
      history.push("/")
    } else {
      setPassword("")
    }
  }

  return (
    <Container className={classes.container} component={Paper} maxWidth="xs">
      <Typography component="h1" variant="h5">Login</Typography>
      <form className={classes.form} onSubmit={(e) => {
        e.preventDefault() // FormEvent のデフォルトの処理が発火しないようにする。
        executeLogin()
      }}>
        <TextField variant="outlined" fullWidth label="User ID" className={classes.input} onChange={(e) => setUserId(e.target.value)} />
        <TextField // input type を切り替えてパスワードの表示・非表示を切り替える。
          variant="outlined" fullWidth label="Password" className={classes.input} type={showPassword ? "text" : "password"}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position={"end"}>
                <IconButton onClick={toggleShowPassword}>
                  {showPassword ? <FontAwesomeIcon icon={faEye} /> : <FontAwesomeIcon icon={faEyeSlash} />}
                </IconButton>
              </InputAdornment>
            )
          }} />
        <Button type={"submit"} variant="contained" fullWidth color="primary" className={classes.input}>Login</Button>
      </form>
      <Notification />
    </Container>
  )
}
