import React, { createContext, useContext, useState } from "react";
import axios from "./http";
import { useNotificationDispatch } from "./notification";

type OperationType = {
  login: (userId: string, password: string) => Promise<boolean>
  logout: () => void
}

type Manager = {
  userId: string
  role: "manager"
}

type Comanager = {
  userId: string
  role: "comanager"
}

type Worker = {
  userId: string
  role: "driver" | "tester"
}

const AuthUserContext = createContext<Manager | Comanager | Worker | null>(null)
const AuthOperationContext = createContext<OperationType>({
  login: async (_) => false,
  logout: () => console.error("Not Implemented.")
})

/**
 * 認証・承認の状態を保持するコンテキストプロバイダー
 * プロバイダー配下のコンポーネントでは、認証・承認情報を共有できる。
 * @param children
 * @constructor
 */
export const AuthenticationProvider: React.FC = ({ children }) => {
  const [user, setUser] = useState<Manager | Comanager | Worker | null>(null)
  const { error } = useNotificationDispatch()

  // ログイン処理、プロバイダがディスパッチする。
  const login = async (userId: string, password: string) => {
    try {
      const response = await axios.post("/login", {
        userId: userId,
        password: password
      })
      setUser({
        userId: userId,
        role: response.data.role
      })
      return true
    } catch (err) {
      error(
        err.response?.data?.detail?.messageJa || `予期せぬエラーが発生しました。システム管理者に連絡してください。 ${err.message}`,
        err.response?.data?.detail?.messageEn || `An unexpected error has occurred. Contact your system administrator. ${err.message}`
      )
      return false
    }
  }

  // ログアウト処理、プロバイダがディスパッチする。
  const logout = async () => {
    try {
      await axios.post("/logout")
    } catch (err) {
      error(
        err.response?.data?.detail?.messageJa || `予期せぬエラーが発生しました。システム管理者に連絡してください。 ${err.message}`,
        err.response?.data?.detail?.messageEn || `An unexpected error has occurred. Contact your system administrator. ${err.message}`
      )
    } finally {
      setUser(null)
    }
  }

  return (
    <AuthOperationContext.Provider value={{ login, logout }}>
      <AuthUserContext.Provider value={user}>
        {children}
      </AuthUserContext.Provider>
    </AuthOperationContext.Provider>
  )
}

// 認証・承認の情報へのアクセス方法を定義

// ログインしているユーザーが管理者の場合は true
export const useIsManager = () => useContext(AuthUserContext)?.role === "manager"

// ログインしているユーザーが委託先管理者の場合は true
export const useIsComanager = () => useContext(AuthUserContext)?.role === "comanager"

// ログインしているユーザーが運転者か計測者の場合は true
export const useIsWorker = () => ["driver", "tester"].includes(useContext(AuthUserContext)?.role || "")

// ユーザーがログインしている場合は true
export const useAuthenticated = () => useContext(AuthUserContext) !== null

// ログインしているユーザーの情報を取得する。ログインしていない場合は null を返却する。
export const useAuthenticatedUser = () => useContext(AuthUserContext)

// ログイン処理
export const useLogin = () => useContext(AuthOperationContext).login

// ログアウト処理
export const useLogout = () => useContext(AuthOperationContext).logout
